import React, { Component, Fragment } from 'react';
import { take, put, call, all } from 'redux-saga/effects';
import * as actions from '../../actions';
import axios, { setAuthHeader } from '../../../Comman/axiosConfig';
// import { SHOW_TOAST } from '../../Common/actions';
// import { CONST_LANG } from '../../Common/constant_language';
import { URL_HOST } from '../../../Comman/constants'




export const fetchTodayCustomersData = async () => {
    try {
      const response = await axios.get(`${URL_HOST}/dashboard/today-total-customers`);

    //   setAuthHeader(response.data.access_token);
      // setCookie('ytel_token', response.data.access_token, 7);
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  };
  

export function* getTodayCustomers() {
  try {
    const response = yield fetchTodayCustomersData();
 
    if (response && response.status == 200) {

      yield all([
        put({
          type: actions.FETCH_TODAY_CUSTOMER_SUCCESS, 
          response: response.data,
        })
      
      ]);
    } else {

      yield all([
        put({
          type: actions.FETCH_TODAY_CUSTOMER_FAILURE,
          response,

        }),
        put({
          type: 'ERROR',
          msgType: 'error',
          header: (response && response.msg) ? response.msg : 'CONST_LANG.LOGIN_ERROR_CONTENT', //CONST_LANG.ERROR_HEADER,
          content: 'errorMessageContent',
        }),
      ]);
    }
  } catch (err) {
    yield all([
      put({ type: actions.FETCH_TODAY_CUSTOMER_FAILURE, err }),
      put({
        type: 'ERROR',
        msgType: 'error',
        header: 'Error',
        content: 'CONST_LANG.LOGIN_FAIL_MSG',
      }),
    ]);
  }
}


export function* watchTodayCustomersSaga() {
  while (true) {
    const state = yield take(actions.FETCH_TODAY_CUSTOMER);
    yield call(getTodayCustomers);
  }
}
