export const HANDLE_CALL_STATUS = 'HANDLE_CALL_STATUS';
export const HANDLE_CALL_STATUS_SUCCESS = 'HANDLE_CALL_STATUS_SUCCESS';
export const HANDLE_CALL_STATUS_FAILURE = 'HANDLE_CALL_STATUS_FAILURE';


export function handleCallStatus() {
  return { type: HANDLE_CALL_STATUS };
}



