/**
 * @RETURN Login page states
 * @author sapna.pastapure@tekphor.com
 */
export const LOGIN_PAGE_STATE = {
    name:'',
    username: "",
    password: "",
    usernameError: "",
    c_passwordError:'',
    nameError:'',
    passwordError: "",
    errorMsg: "",
    loading: false,
    all_roles:[],
    all_branches:[],
    showPW:false,
    showCPW:false,
    isRegister:false,
    isLogin:true,
    c_password:'',
    c_passwordConfimError:'',
    user_type:'',
    userTypeError:'',
    banks:[],
    bank_id:'',
    bankError:'',
    inactive_account:false,
    isActivation:false
}