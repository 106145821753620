
/**
 *  @purpose Hide show password
 *  @author sapna.pastapure@tekphor.com
 */
export const showPass = {
    fontSize: '15px',
      marginLeft: '-22px',
      zIndex: '10',
      marginTop: '9px',
      marginRight:'7px',
      cursor: 'pointer'
  };

/**
 *  @purpose manage custome css of cursor
 *  @author sapna.pastapure@tekphor.com
 */
export const handleType = {
    cursor:'pointer',
    textDecoration: 'revert'
};


/**
 *  @purpose manage custome css of sctive account cursor
 *  @author sapna.pastapure@tekphor.com
 */
export const active_account = {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'blue',
    marginLeft: '48px',
};



/**
 *  @purpose manage custome css of error msg
 *  @author sapna.pastapure@tekphor.com
 */
export const errorMsgcSS={
    marginTop: '10px',
    marginBottom: '-26px'
}

/**
 *  @purpose manage custome css 
 *  @author sapna.pastapure@tekphor.com
 */
export const customCss = {
    footerCss:{
        marginTop: '28px',
        marginBottom: -'16px',
        textAlign: 'center'
    }
}
