// import { configureScope } from '@sentry/browser';
import * as ActionTypes from "../actions";
import { setAuthHeader } from "../../Comman/axiosConfig";
import {
  setLoggedInUser,
} from "../../Comman/functions";

export const defaultLoginState = {
  isAttemptingLogin: false,
  payload: null,
  isSuccessMsg:null
};

export function ProfileReducer(state = defaultLoginState, action) {
  switch (action.type) {
    case ActionTypes.PROFILE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSuccessMsg:null
      };

    case ActionTypes.PROFILE_SUCCESS: {
      const loggedInUser = {
        username: action.response.data.email,
        email: action.response.data.email,
        id: action.response.data.id,
        profile: action.response.data.image,
      };
 

      setLoggedInUser(JSON.stringify(loggedInUser));



      return {
        ...state, 
        isLoading: false,
        payload: action.response.data,
        isSuccessMsg : action.response.msg,
      };
    }
    case ActionTypes.PROFILE_SUCCESS_HIDE: {
  

      return {
        ...state, 
        
        isSuccessMsg : "",
      };
    }

    
    case ActionTypes.PROFILE_FAILURE:
      return {
        ...state,
        isLoading: false,
        payload: null,
        isSuccessMsg:null
      };

  
    default:
      return state;
  }
}
