import axios from 'axios';
import { getUserToken } from './functions';

// prettier-ignore
// function updateAuthHeader(token) {
//   if (getCookie('accessToken')) {
//     axios.defaults.headers.common.Authorization = `Bearer ${getCookie('accessToken')}`;
//   }
//   if (token) {
//     axios.defaults.headers.common.Authorization = `Bearer ${token}`;
//   }
// }
function updateAuthHeader(token = '') {
  if (getUserToken('accessToken')) {
    axios.defaults.headers.common.Authorization = `${getUserToken(
      'accessToken',
    )}`;
  }
  if (token) {
      axios.defaults.headers.common.Authorization = `${token}`;
  }
}

updateAuthHeader(); 

export function setAuthHeader(token) {
  updateAuthHeader(token);
}

export default axios;
