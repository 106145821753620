import React, { useState, useEffect } from "react";
import "../../containers/common.css";
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CRow,
} from "@coreui/react";
import { Forgot } from "../../components/Auth";
import LoginPage from "../../components/Auth/LoginPage";


/**
 * @purpose Login component 
 * @author sapna.pastapure@tekphoe.com
 * @returns Component
 */
export const Login = () => {
  const [isForgotPass, setIsForgotPass] = useState(false);
  const [LoginText, setLoginText] = useState('');
  const [LoginBGColor, setLoginBGColor] = useState('');
  const [LoginForUser, setLoginForUser] = useState('');


  // Get component onload events
  useEffect(() => {
    // Update the document title using the browser API
    const Hostname = '';

    if(window.location.hostname == 'mysvslabs.com') {
      console.log('current host name = ', window.location.hostname)
      setLoginText('MYSVSLABS !')
      setLoginBGColor('orange')
      setLoginForUser('US Test Bed')

    } else if(window.location.hostname == 'mturbine.com') {
      // setLoginText('mTurbine !')
      // setLoginBGColor('green')
      // setLoginForUser('India Dev')
      setLoginText('TURANT WORLD !')
      setLoginBGColor('#321fdb')
      setLoginForUser('get Turant')
    } else if ( window.location.hostname == 'turant.world'){
      console.log('current host name = ', window.location.hostname)
      setLoginText('TURANT WORLD !')
      setLoginBGColor('#321fdb')
      setLoginForUser('get Turant')
    } else {
      console.log('useEffect else ')
      // setLoginText('SA Healthcare !')
      setLoginText('TURANT WORLD !')
      setLoginBGColor('#321fdb')
      setLoginForUser('get Turant')
    }
    console.log(window.location.hostname)
    console.log('useEffect')
  }, []);

  /**
   * Display forgot page
   */
  const handleForgotPass = () => {
    setIsForgotPass(true);
  };

  /**
   * Display login page
   */
  const handleLoginPage = () => {
    setIsForgotPass(false);
  };


  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center"> 
          <CCol md="8">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  {isForgotPass ? (
                    <Forgot handleLoginPage={handleLoginPage} />
                  ) : (
                    <LoginPage handleForgotPass={handleForgotPass} />
                  )}
                </CCardBody>
              </CCard> 
              <CCard
                className={`text-white  py-5 d-md-down-none`}
                style={{ width: "44%", backgroundColor:LoginBGColor }}
              >
                <CCardBody className="text-center">
                  <div>
                    <h2>{LoginText}</h2> 
                    {isForgotPass ? (
                      <p>
                        Please <b>Forgot</b> your password to <b>Login</b>.{" "}
                      </p>
                    ) : (
                      <p>
                        Please <b>Login</b> for <b>{LoginForUser}</b> access.{" "}
                      </p>
                    )}

                    {/* <Link to="/register">
                        <CButton color="primary" className="mt-3" active tabIndex={-1}>Register Now!</CButton>
                      </Link> */}
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Login;
