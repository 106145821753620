import React, { Component } from "react";
import "../../containers/common.css";
import { ERROR_VALIDATIONS } from "../../../Comman/constants";
import { resetPassword } from "../../servicers/common";
import { ResetPassword } from "../../components/Auth";

import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";

export class Reset extends Component {
  state = {
    username: "",
    password: "",
    confirm_password: "",
    usernameError: "",
    passwordError: "",
    confirmPasswordError: "",
    errorMsg: "",
    resetMsg: "",
    loading: false,
    reset_token: "",
  };

  componentDidMount() {
    const { token } = this.props.match.params;
    if (token) {
      this.setState({
        reset_token: token,
      });
    }
  }

  validateInput = (e) => {
    const field = e.target.name;
    const val = e.target.value;
    switch (field) {
      case "username":
        this.validateUsername(val);
        break;
      case "password":
        this.validatePassword(val);
        break;

      default:
        console.warn("validateInput was supplied an uncaught field case");
        break;
    }
  };

  /**
   * Validate input username
   */
  validateUsername = (val) => {
    if (!ERROR_VALIDATIONS.REGEX__EMAIL.test(val)) {
      this.setState({
        usernameError: ERROR_VALIDATIONS.TEXT__VALIDATION_ERROR__USERNAME,
      });
      return true;
    }
    this.setState({ usernameError: "" });
    return false;
  };

  /**
   * Validate input passowrd
   */
  validatePassword = (val) => {
    if (val.length < 1) {
      this.setState({
        passwordError: ERROR_VALIDATIONS.TEXT__VALIDATION_ERROR__PASSWORD_EMPTY,
      });
      return true;
    }
    this.setState({ passwordError: "" });
    return false;
  };
 
  /**
   * Validate input confirm_password with password
   */
  validateConfirmPassword = (val) => {
    const { password, confirm_password } = this.state;

    if (val.length < 1) {
      this.setState({
        confirmPasswordError:
          ERROR_VALIDATIONS.TEXT__VALIDATION_ERROR__CONFIRM_PASSWORD_EMPTY,
      });
      return true;
    } else if (password != confirm_password) {
      this.setState({
        confirmPasswordError:
          ERROR_VALIDATIONS.TEXT__VALIDATION_ERROR__CONFIRM_PASSWORD_UNMATCH,
      });
      return true;
    }

    this.setState({ confirmPasswordError: "" });
    return false;
  };

  /**
   * Validate input username and password
   */
  validateRequired = () => {
    const { username, password, confirm_password } = this.state;
    const usernameCheck = this.validateUsername(username);
    const passwordCheck = this.validatePassword(password);
    const confirmPasswordCheck = this.validateConfirmPassword(confirm_password);

    if (usernameCheck || passwordCheck || confirmPasswordCheck) {
      return true;
    }
    return false;
  };

  /**
   * Handle inputs
   */
  handleChangeInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  /**
   * Submit login form
   */
  submitForm = (e) => {
    e.preventDefault();

    // proceed if no validation errors
    const { username, password, confirm_password, reset_token } = this.state;
    const result = this.validateRequired();

    if (!result) {
      this.setState({ loading: true });
      const payload = {
        token: reset_token,
        email: username,
        password,
        confirm_password,
      };

      resetPassword(payload)
        .then((res) => {
          if (res.data && res.data.status == 200) {
            this.setState({
              resetMsg: res.data.msg,
              loading: false,
              errorMsg: "",
            });
          } else {
            this.setState({
              errorMsg: res.data.msg,
              loading: false,
              resetMsg: "",
            });
          }
        })
        .catch((err) => {
          this.setState({
            errorMsg: err.response ? err.response.data.msg : "",
            loading: false,
          });
        });
    }
  };

  /**
   * Handle login page
   */
  handleLoginPage = () => {
    window.open("http://localhost:3000/#/login", "_blank");
  };

  render() {
    const {
      username,
      password,
      confirm_password,
      usernameError,
      passwordError,
      confirmPasswordError,
      errorMsg,
      loading,
      resetMsg,
    } = this.state;
    const { handleForgotPass } = this.props;
    return (
      <div className="c-app c-default-layout flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md="8">
              <CCardGroup>
                <CCard className="p-4">
                  <CCardBody>
                    <ResetPassword
                      username={username}
                      password={password}
                      confirm_password={confirm_password}
                      usernameError={usernameError} 
                      passwordError={passwordError}
                      confirmPasswordError={confirmPasswordError}
                      errorMsg={errorMsg}
                      loading={loading}
                      resetMsg={resetMsg}
                      handleForgotPass={handleForgotPass}
                      handleLoginPage={this.handleLoginPage}
                      submitForm={this.submitForm}
                      handleChangeInput={this.handleChangeInput}
                      validateInput={this.validateInput}
                    />
                  </CCardBody>
                </CCard>
                <CCard
                  className="text-white bg-primary py-5 d-md-down-none"
                  style={{ width: "44%" }}
                >
                  <CCardBody className="text-center">
                    <div>
                      <h2>TURANT</h2>
                      <p>
                        Please <b>Reset Your Password</b> for get <b>Turant</b>{" "}
                        access.{" "}
                      </p>

                      {/* <Link to="/register">
                            <CButton color="primary" className="mt-3" active tabIndex={-1}>Register Now!</CButton>
                          </Link> */}
                    </div>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    );
  }
}

// export default ResetPage;
