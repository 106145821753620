import React, { Fragment } from "react";
import moment from "moment";


export const KeyConfiguration = (props) => {
  const {
    fetchingData,
    refreshSecretKeyApi,
    secretKeyObj
  } = props;


  return (
    <Fragment>
        <span>
        <table className="table" style={{ marginTop: '12px'}}>
            <thead className="thead-dark">
                <tr>
                <th scope="col">#</th>
                <th scope="col">Secret Key</th>
                <th scope="col">Status</th>
                <th scope="col">Created Date</th>
                </tr>
            </thead>
            <tbody>
                {secretKeyObj && secretKeyObj != null}
                {fetchingData ? (
                  <tr>
                     <td>
                       <span>Please be patient while data loading...</span>
                     </td>
                  </tr>
                ) : (
                   <tr>
                    <th scope="row">{ typeof secretKeyObj.id !== 'undefined' &&  secretKeyObj.id}</th>
                    <td>{secretKeyObj.secret_key}</td>
                    <td>{secretKeyObj.is_active == 1 ? 'Yes' : 'No'}</td>
                
                    {/* <td>{ moment(secretKeyObj.created_at).format('MMMM Do YYYY, h:mm:ss a')}</td> */}
                    <td>{moment(secretKeyObj.created_at).fromNow()}
                    <span>
                        <span  onClick={refreshSecretKeyApi}>
                          <i className="fa fa-refresh refresh_screte_key"></i>
                        </span>
                    </span>
                    </td>
                    </tr>
                )}

             
            </tbody>
            </table>
        </span>
    </Fragment>
  );
};
