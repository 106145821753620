import axios from 'axios'
import {URL_HOST} from '../../Comman/constants'


/**
 * Fetch customer login details
 * @param 
 */
 export const fetchCustomerLoginDetails = (id) =>
 axios.get(`${URL_HOST}/mobile/account-details/${id}`);


 