import { all, fork } from "redux-saga/effects";

// LOGIN
import { watchLogin } from "../components/Auth/sagas/index";
import {
  watchFetchRoles,
  watchFetchBranches,
  watchFetchStatus,
} from "../components/Comman/sagas/index";
import { watchProfileSaga } from "../components/Profile/sagas/index";
import {
  watchFetchTeamLeadsSaga,
  watchFetchSaleAgentsSaga,
  watchFetchLoanTypeSaga,
  watchFetchLanguagesSaga,
  watchFetchEndUsersSaga,
  watchHandleCallStatus

} from "../components/EndUser/sagas/index";

import {
  watchCustomersSaga,
  watchEndUserSaga,
  watchUploadRecordsSaga,
  watchCallsAttemptedSaga,
  watchVerificationCallsSaga,
  watchRegistratioCallsSaga,

  watchTodayEndUserSaga,
  watchTodaysCallsAttemptedSaga,
  watchTodayCustomersSaga,
  watchTodayRegistratioCallsSaga,
  watchTodayUploadRecordsSaga,
  watchTodayVerificationCallsSaga
} from '../components/Dashboard/sagas/index'




export default function* sagas() {
  yield all([
    fork(watchLogin),
    fork(watchFetchRoles),
    fork(watchFetchBranches),
    fork(watchFetchStatus),
    fork(watchProfileSaga),
    fork(watchFetchTeamLeadsSaga),
    fork(watchFetchSaleAgentsSaga),
    fork(watchFetchLoanTypeSaga),
    fork(watchFetchLanguagesSaga),
    fork(watchFetchEndUsersSaga),
    fork(watchCustomersSaga),
    fork(watchEndUserSaga),
    fork(watchUploadRecordsSaga),
    fork(watchCallsAttemptedSaga),
    fork(watchVerificationCallsSaga),
    fork(watchRegistratioCallsSaga),
    fork(watchHandleCallStatus),
    fork(watchTodayEndUserSaga),
    fork(watchTodaysCallsAttemptedSaga),
    fork(watchTodayCustomersSaga),
    fork(watchTodayRegistratioCallsSaga),
    fork(watchTodayUploadRecordsSaga),
    fork(watchTodayVerificationCallsSaga),

  ]);
}
