export const FETCH_TEAM_LEADS = 'FETCH_TEAM_LEADS';
export const FETCH_TEAM_LEADS_SUCCESS = 'FETCH_TEAM_LEADS_SUCCESS';
export const FETCH_TEAM_LEADS_FAILURE = 'FETCH_TEAM_LEADS_FAILURE';


export function fetchTeamLeads() {
  return { type: FETCH_TEAM_LEADS };
}



