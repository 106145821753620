import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import ReactLoading from 'react-loading';
import {
  CAlert,
  CButton,
  CCol,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend, 
  CInputGroupText,
  CRow,
  CSelect,
  CInvalidFeedback
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import "../../containers/common.css";
import {
  REGEX__EMAIL,
  TEXT__VALIDATION_ERROR__USERNAME,
  TEXT__VALIDATION_ERROR__NAME_EMPTY,
  TEXT__VALIDATION_ERROR__PASSWORD_EMPTY,
  TEXT__VALIDATION_ERROR__CONFIRM_PASSWORD_UNMATCH
} from "../../../Comman/constants";
import firebase from '../../../Comman/firebase'
import {  ContentLoading, InvertContentLoader } from "../../../Comman/components";
import { registerUser, bankLists, login, resendActivationLinkAPI } from "../../servicers/common";
import { attemptLogin } from '../../actions/index'
import {URL_HOST} from '../../../Comman/constants'
import {showPass, handleType, active_account, errorMsgcSS, customCss} from './customCss'
import { LOGIN_PAGE_STATE } from "../../constants";


/**
 * @purpose Login Page component 
 * @author sapna.pastapure@tekphor.com
 * @returns Component
 */
class LoginPage extends Component {
  state = LOGIN_PAGE_STATE;


  // Call Component onLoad event ( sapna.pastapure@tekphor.com )
  componentDidMount() {
    // Fetch all bank lists from server
    this.fetchBankList();
  }

  // Call component after redux state changed ( sapna.pastapure@tekphor.com )
  componentDidUpdate(prevProps) {
    const {loginPayload} = this.props
    if(prevProps.loginPayload != loginPayload) {
      console.log('login payload', loginPayload)
    } // End if
  }


  /**
   * @purpose Fetch bank list from server
   * @author sapna.pastapure@tekphor.com
   * @return state
   */
  fetchBankList = ()=> {
    // Call service API
    bankLists().then(response => {
      console.log('bank list = ', response.data)
      this.setState({ banks:response.data.data });
    }).catch(err => {
      console.log('bank error = ', err)
    }) // End try-catch
  } // End function

  /**
   * @purpose Validate input
   * @author sapna.pastapure@tekphor.com
   * @return state value
   */
  validateInput = (e) => {
    const field = e.target.name;
    const val = e.target.value;

    switch (field) {
      case "username":
        this.validateUsername(val, 'email');
        break;
      case "password":
        this.validatePassword(val, 'password');
        break;
      case "name":
        this.validateUsername(val, 'name');
        break;
      case "c_password":
        this.validatePassword(val, 'c_password');
        break;
        
      default:
        console.warn("validateInput was supplied an uncaught field case");
        break;
    } // End switch
  }; // End function

  /**
   * @purpose Validate input username
   * @author sapna.pastapure@tekphor.com
   * @return boolean value
   */
  validateUsername = (val, type) => {
    if(type == 'email') {
      if (!REGEX__EMAIL.test(val)) {
        this.setState({ usernameError: TEXT__VALIDATION_ERROR__USERNAME });
        return true;
      } // End if

      this.setState({ usernameError: "" });
    } else if(type == 'name') {
      if (val.length < 0) {
        this.setState({ nameError: TEXT__VALIDATION_ERROR__NAME_EMPTY });
        return true;
      } // End if

      this.setState({ nameError: "" });
    }  // End if-else

    return false;
  }; // End function

  /**
   * @purpose Validate input name
   * @author sapna.pastapure@tekphor.com
   * @return boolean value
   */
   validateName = (val, type) => {
    if(type == 'name') { 
      if (val.length < 1) {
        this.setState({ nameError: 'Must provide a name!' });
        return true;
      } // End if

      this.setState({ nameError: "" });
    } // End if

    return false;
  }; // End function

  /**
   * @purpose Validate input password
   * @author sapna.pastapure@tekphor.com
   * @return boolean value
   */
  validatePassword = (val, type) => {
    if(type == "password") {
      if (val.length < 1) {
        this.setState({ passwordError: TEXT__VALIDATION_ERROR__PASSWORD_EMPTY });
        return true;
      } // End if
      this.setState({ passwordError: "" });
    }  // End if

    return false;
  }; // End function

  /**
   * @purpose Validate input confirm password
   * @author sapna.pastapure@tekphor.com
   * @return boolean value
   */
  validateCPassword = (val, type) => {
    const {password} = this.state;
  
      if (val.length < 1) {
        this.setState({ c_passwordError: TEXT__VALIDATION_ERROR__PASSWORD_EMPTY });

        return true;
      } else if(password != val) {
        this.setState({ c_passwordConfimError: TEXT__VALIDATION_ERROR__CONFIRM_PASSWORD_UNMATCH });

        return true;
      } // End if-else

      this.setState({ c_passwordError: "", c_passwordConfimError:'' });

      return false;
  }; // End function


  /**
   * @purpose Validate input for registration
   * @author sapna.pastapure@tekphor.com
   * @return boolean value
   */
  validateRequiredForRegister = () => {
    // Validate name
    const nameCheck = this.validateName(this.state.name, 'name');
    
    // Validate username
    const usernameCheck = this.validateUsername(this.state.username, 'email');

    // Validate password
    const passwordCheck = this.validatePassword(this.state.password, 'password');

    // Validate confirm password
    const c_passwordCheck = this.validateCPassword(this.state.c_password);
    
    if (usernameCheck || nameCheck || passwordCheck || c_passwordCheck) {  return true; } // End if

    return false;
  }; // End if


  /**
   * @purpose Validate input for registration
   * @author sapna.pastapure@tekphor.com
   * @return boolean value
   */
  validateRequired = () => {
    // Validate username
    const usernameCheck = this.validateUsername(this.state.username, 'email');
    
    // Validate password
    const passwordCheck = this.validatePassword(this.state.password, 'password');
  
    if (usernameCheck || passwordCheck ) { return true; } // End if

    return false;
  }; // End if

    
  /**
   * @purpose Validate input for registration
   * @author sapna.pastapure@tekphor.com
   * @return boolean value
   */
  validateRegisterRequired = () => {
    const {name, user_type, bank_id } = this.state;

     // Validate name
    const nameCheck = this.validateName(this.state.name, 'name');

    // Validate username
    const usernameCheck = this.validateUsername(this.state.username, 'email');

    // Validate password
    const passwordCheck = this.validatePassword(this.state.password, 'password');

    // Validate confirm password
    const c_passwordCheck = this.validateCPassword(this.state.c_password);
    
    if(user_type.length < 0 || user_type == '')  {
      this.setState({ userTypeError:'Please select user type !' })

      return true;
    } else {  this.setState({ userTypeError:'' }); } // End if-else

    if(user_type == 'int') {
      if(bank_id.length < 1) {
        this.setState({ bankError:'Please select bank name !' })

        return true;
      } else {  this.setState({  bankError:'' }); } // End if-else
    } // End if

    if (nameCheck || usernameCheck || passwordCheck || c_passwordCheck) { return true; } // End if

    return false;
  }; // End if

  /**
   * @purpose Handle input on change event
   * @author sapna.pastapure@tekphor.com
   * @return state value
   */
  handleChangeInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }; // End function
  
  /**
   * @purpose Handle enter event for login
   * @author sapna.pastapure@tekphor.com
   * @return null
   */
  handleLoginEvent = (e) => {
    if(e.key == 'Enter') { this.submitForm(e) } // End if
  } // End function 

  /**
   * @purpose Handle login submit form event
   * @author sapna.pastapure@tekphor.com
   * @return component Or error
   */
  submitForm = (e) => {
    e.preventDefault();
    // proceed if no validation errors
    const { name, username, password, user_type, isLogin, bank_id } = this.state;
    if(isLogin) {
      //**************************************************** Login ***********************************************************

      // Check required inputs
      const result = this.validateRequired();
  
      if (!result) {
        this.setState({ loading: true });
        const data = {
          email: username,
          password: password,
        };
        
        // Call login sage 
        this.props.login(username, password);

        // Call login serveri for firebase auth
        login(data)
          .then((res) => {
            console.log("Success response  fff = ", res.data);
            if (res.data && res.data.token) {
              firebase.auth().signInWithEmailAndPassword(username, password)
              .then((user1) => {
                console.log('auth firebase login with email pass = ', user1)
              })
              .catch((error) => {
                // Firebase auth with username and password                  
                firebase.auth().createUserWithEmailAndPassword(username, password)
                .then((user) => {
                  console.log('auth firebase create with email pass = ', user)
                })
                .catch((error) => {
                  var errorCode = error.code;
                  var errorMessage = error.message;
                  console.log('auth firebase create with email pass  error= ')
                  console.log(errorCode)
                  console.log(errorMessage)
                });
              }); // End try-catch
            } else {
              console.log("login error reponse = ", res.data);
              if(res.data.code == 404) {
                const mesg = res.data.msg;
                this.setState({ errorMsg: mesg, loading: false, inactive_account:true });
              } else {  this.setState({ errorMsg: res.data.msg, loading: false, inactive_account:false }); } // End if-else
            } // End if-else
          })
          .catch((err) => {
            console.log("error reponse = ", err.response);
            this.setState({
              errorMsg: err.response != undefined ? err.response.data.message != undefined ? err.response.data.message : 'Somthing went wrong !' : err.response ? err.response.message : 'Network issue ! ',
              loading: false,
            });
            let _this = this;
            setTimeout(function () {
              _this.setState({ errorMsg: "" });
            }, 4000);
  
          }); // End try-catch
      } // End if
    } else {
      // **************************************************** Registration ***********************************************************

      // Validate required input for user registration
      const result = this.validateRegisterRequired();
      if (!result) {
        this.setState({ loading: true });
        const data = {
          name:name,
          email: username,
          password: password,
          user_type,
          bank_id
        };
        
        // Call service for register new user
        registerUser(data)
          .then((res) => {
            if (res.data && res.data.status == 200) { this.setState({ successMsg: res.data.msg, loading: false, isLogin:true, isRegister:false }); } 
            else { this.setState({ errorMsg: res.data.msg, loading: false }); } // End if-else
  
            let _this = this;
            setTimeout(function () { _this.setState({ errorMsg: "", successMsg:'' }); }, 4000);
          })
          .catch((err) => {
            console.log("error reponse = ", err.response);
            this.setState({
              errorMsg: err.response != undefined ? err.response.data.message != undefined ? err.response.data.message : 'Somthing went wrong !' : err.response ? err.response.message : 'Network issue ! ',
              loading: false,
            });

            let _this = this;
            setTimeout(function () {  _this.setState({ errorMsg: "" }); }, 4000);
          }); // End try-catch
      } // End if
    } // End if-else
  }; // End submit function


  /**
   * @purpose Handle onclick event
   * @author sapna.pastapure@tekphor.com
   * @return states
   */
  handleRegisterUser = (type) => {
    if(type == 'login') {
      this.setState({
        isLogin:true,
        isRegister:false,
        usernameError:'',
        passwordError:'',
        nameError:'',
        username: "",
        password: "",
        c_password:'',
        errorMsg:'',
        successMsg:'',
      })
    } else {
      this.setState({
        isLogin:false,
        isRegister:true,
        usernameError:'',
        passwordError:'',
        nameError:'',
        username: "",
        password: "",
        c_password:'',
        errorMsg:'',
        successMsg:'',
      })
    } // End if-else
  } // End function

  /**
   * @purpose Handle login type
   * @author sapna.pastapure@tekphor.com
   * @return state
   */
  handleLoginType = (type) => {  this.setState({ user_type:type }) } // End function

  /**
   * @purpose Handle activation mail
   * @author sapna.pastapure@tekphor.com
   * @return state
   */
  handleActivationMail = () => {
    this.setState({ isActivation:true })
    const {username} = this.state;
    // Set payload
    const payload = { email:username }

    // Call the service for activation mail
    resendActivationLinkAPI(payload).then(res => {
      if(res.data.status == 200) { this.setState({ successMsg:res.data.msg, isActivation: false, errorMsg:'', inactive_account:false }); }
      else {
        if(res.data.code == 404) {
          const mesg = res.data.msg;

          this.setState({ errorMsg: mesg, isActivation: false, inactive_account:true });
        } else { this.setState({ errorMsg: res.data.msg, isActivation: false, inactive_account:false }); } // End if-else
      } // End if-else
      
      let _this = this;
      setTimeout(function () {
        _this.setState({ errorMsg: "", successMsg:''});
      }, 4000);
    }).catch(err => {
      console.log('activation error = ', err)
      console.log("error reponse = ", err.response);
      this.setState({
        errorMsg: err.response != undefined ? err.response.data.message != undefined ? err.response.data.message : 'Somthing went wrong !' : err.response ? err.response.message : 'Network issue ! ',
        isActivation: false,
      });
      let _this = this;
      setTimeout(function () {
        _this.setState({ errorMsg: "" });
      }, 4000);
    })
  
  }


  openVoiceVerify = () => {
    window.open(
      `${URL_HOST}/voice-verify/56238957?fromweb=1`, "_blank");
  }


  render() {
    const {
      username,
      password,
      usernameError,
      passwordError,
      errorMsg,
      loading,
      isRegister,
      isLogin,
      showPW,
      showCPW,
      name, 
      nameError,
      c_password,
      c_passwordError,
      c_passwordConfimError,
      user_type,
      userTypeError,
      banks,
      bank_id,
      bankError,
      successMsg,
      inactive_account,
      isActivation
    } = this.state;
    const {handleForgotPass} = this.props

      console.log('nameError nameError = ', nameError)
    return (
      <div>
        <CForm>
          <h1 style={{textAlign: 'center'}}>{isLogin ? 'Login' :'Register'}</h1>

          <p className="text-muted"  style={{textAlign: 'center'}}> {isLogin ? 'Login to Your Own Voice Verify Engine  !' : 'Sign Up with new user !'}</p>
          {/* {isRegister ? (
            <Fragment>
              <p>You already have account <a  style={handleType}   onClick={() => this.handleRegisterUser('login')}><b>Login</b></a> with existing user !</p>
            </Fragment>
          ) : (
            <Fragment>
              <p>If don't have account You can <a style={handleType} onClick={() => this.handleRegisterUser('register')}><b  >Register</b></a> new user with <b>Us</b> !</p>
            </Fragment>
          ) } */}
          
          {errorMsg && (
            <CAlert color="danger">
              {/*eslint-disable-next-line*/}
             
              {inactive_account ? (
                <Fragment>
                     {`Error : ${errorMsg} `}  <br /> <a style={active_account} onClick={() => this.handleActivationMail()}>Resend Activation Link</a> 
                </Fragment>
              ) : (
                <Fragment>
                   {`Error : ${errorMsg}`}
                </Fragment>
              )}
            </CAlert>
          )}
            {successMsg && (
            <CAlert color="success">
              {/*eslint-disable-next-line*/}
              {`Success : ${successMsg}`}
            </CAlert>
          )}
  
  
          {isLogin ? (
            <Fragment>
              <CInputGroup className="mb-3 input_class">
                <CInputGroupPrepend>
                  <CInputGroupText>
                    <CIcon name="cil-user" />
                  </CInputGroupText>
                </CInputGroupPrepend>
                <CInput
                  type="text"
                  placeholder="Username"
                  name="username"
                  autoComplete="username"
                  onChange={this.handleChangeInput}
                  value={username}
                  onBlur={(e) => {
                    if (username.length > 0) {
                      this.validateInput(e);
                    }
                  }}
                />
              </CInputGroup>
         
              <div
                 className={usernameError!= '' ? 'validationRegisterErrorMessagesStyles' : 'validationErrorMessagesStyles'}
                style={{ textAlign: "left" }}
              >
                {usernameError}
              </div>
              {isActivation && <ReactLoading type={'spin'} color={'black'}  className="loading" />}

              <CInputGroup className="mb-4 input_class">
                <CInputGroupPrepend>
                  <CInputGroupText>
                    <CIcon name="cil-lock-locked" />
                  </CInputGroupText>
                </CInputGroupPrepend>
                <CInput
                  type={showPW ? 'text' : 'password'}
                  placeholder="Password"
                  name="password"
                  autoComplete="password"
                  onChange={this.handleChangeInput}
                  value={password}
                  onKeyPress={this.handleLoginEvent}
                />
    
                  {showPW ? (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                    <i 
                      className="fa fa-eye-slash" style={showPass}
                      onClick={() => this.setState({ showPW: false })}
                    />
                  ) : (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                    <i 
                      className="fa fa-eye" style={showPass}
                      onClick={() => this.setState({ showPW: true })}
                    />

                  )}
              </CInputGroup>
              <div
                   className={passwordError!= '' ? 'validationRegisterErrorMessagesStyles' : 'validationErrorMessagesStyles'}
                style={{ textAlign: "left" }}
              >
                {passwordError}
              </div>
              <CRow>
                <CCol xs="6">
                  <CButton
                    color="primary"
                    className="px-4 login_bt"
                    onClick={this.submitForm}
                  >
                    Login
                    {loading && <InvertContentLoader />}
                  </CButton>
                </CCol>
                <CCol xs="6" className="text-right">
                  <CButton color="link" className="px-0" onClick={handleForgotPass}>
                    Forgot Password?
                  </CButton>
                </CCol>
              </CRow>
              <span>
                      <p style={customCss.footerCss}>Don't have an account ? 
                      <CButton color="link" className="px-0" onClick={() => this.handleRegisterUser('register')}>
                        Register
                      </CButton>
                     
                        </p>
                    </span>
                    <span className="text-center">
                      <CButton
                        color="primary"
                        className="px-4  btn-block"
                        onClick={this.openVoiceVerify}
 
                      >
                        Login With VoiceVerify&#8482;
                        {/* {loading && <InvertContentLoader />} */}
                      </CButton>
                    </span>
            </Fragment>
          ) : (
            <Fragment>
               <div
                      className="validationErrorMessagesStyles"
                      style={errorMsgcSS}
                    >
                      {userTypeError}
                    </div>
                <div className="form-check form-check-inline" style={{ marginTop: '35px'}}>
                  <input className="form-check-input" type="radio" onClick={() => this.handleLoginType('Ent')} name="flexRadioDefault" id="flexRadioDefault1"  />
                  <label className="form-check-label" for="flexRadioDefault1">
                  Enterprises
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" name="flexRadioDefault" onClick={() => this.handleLoginType('Ind')}  id="flexRadioDefault2" />
                  <label className="form-check-label" for="flexRadioDefault2">
                    Individual Developer
                  </label>
                </div>
               
                
                <div style={{marginTop:'14px'}}>
                  <CInputGroup className="mb-3 input_class">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-user" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        type="text"
                        placeholder="name"
                        name="name"
                        autoComplete="off"
                        onChange={this.handleChangeInput}
                        value={name}
                        onBlur={(e) => {
                          if (name.length > 0) {
                            this.validateInput(e);
                          }
                        }}
                      />
                    </CInputGroup>
                    <div
                       className={nameError!= '' ? 'validationRegisterErrorMessagesStyles' : 'validationErrorMessagesStyles'}
                      style={{ textAlign: "left" }}
                    >
                      {nameError}
                    </div>

                  <CInputGroup className="mb-3 input_class">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-user" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        type="text"
                        placeholder="Username"
                        name="username"
                        autoComplete="username"
                        onChange={this.handleChangeInput}
                        value={username}
                        onBlur={(e) => {
                          if (username.length > 0) {
                            this.validateInput(e);
                          }
                        }}
                      />
                    </CInputGroup>
                    <div
                        className={usernameError!= '' ? 'validationRegisterErrorMessagesStyles' : 'validationErrorMessagesStyles'}
                      style={{ textAlign: "left" }}
                    >
                      {usernameError}
                    </div>

                    <CInputGroup className="mb-4 input_class">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-lock-locked" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        type={showPW ? 'text' : 'password'}
                        placeholder="Password"
                        name="password"
                        autoComplete="password"
                        onChange={this.handleChangeInput}
                        value={password}
                        onKeyPress={this.handleLoginEvent}
                      />
          
                        {showPW ? (
                          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                          <i 
                            className="fa fa-eye-slash" style={showPass}
                            onClick={() => this.setState({ showPW: false })}
                          />
                        ) : (
                          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                          <i 
                            className="fa fa-eye" style={showPass}
                            onClick={() => this.setState({ showPW: true })}
                          />

                        )}
                    </CInputGroup>
                    <div
                      className={passwordError!= '' ? 'validationRegisterErrorMessagesStyles' : 'validationErrorMessagesStyles'}
                      style={{ textAlign: "left" }}
                    >
                      {passwordError}
                    </div>


                    <CInputGroup className="mb-4 input_class">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-lock-locked" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        type={showCPW ? 'text' : 'password'}
                        placeholder="Confirm Password"
                        name="c_password"
                        autoComplete="c_password"
                        onChange={this.handleChangeInput}
                        value={c_password}
                        // onKeyPress={this.handleLoginEvent}
                      />
          
                        {showCPW ? (
                          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                          <i 
                            className="fa fa-eye-slash" style={showPass}
                            onClick={() => this.setState({ showCPW: false })}
                          />
                        ) : (
                          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                          <i 
                            className="fa fa-eye" style={showPass}
                            onClick={() => this.setState({ showCPW: true })}
                          />

                        )}
                    </CInputGroup>
                    <div
                     className={(c_passwordError!= '' || c_passwordConfimError != '') ? 'validationRegisterErrorMessagesStyles' : 'validationErrorMessagesStyles'}
                      style={{ textAlign: "left" }}
                    >
                      {c_passwordError}
                      {c_passwordConfimError}
                    </div>

                        {user_type == 'int' && (
                          <Fragment>
                            <CInputGroup className="mb-4 input_class">
                            <CInputGroupPrepend>
                              <CInputGroupText>
                                <CIcon name="cil-lock-locked" />
                              </CInputGroupText>
                            </CInputGroupPrepend>

                              <CSelect
                                custom
                                name="bank_id"
                                id="bank_id"
                                invalid={bankError}
                                onChange={this.handleChangeInput}
                                value={bank_id}
                              >
                                <option value="">Please select</option>
                                {banks ? (
                                  banks.map((item) => (
                                    <option value={item.id}>{item.bank_name}</option>
                                  ))
                                ) : (
                                  <option value="">No Banks</option>
                                )}
                              </CSelect>
                          </CInputGroup>
                          <CInvalidFeedback>
                            {bankError}
                          </CInvalidFeedback>
                          </Fragment>
                        )}




                    

                    <CRow>
                      <CCol xs="6">
                        <CButton
                          color="primary"
                          className="px-4 login_bt"
                          onClick={this.submitForm}
                        >
                          Register
                          {loading && <InvertContentLoader />}
                        </CButton>
                      </CCol>
                      <CCol xs="6" className="text-right">
                        <CButton color="link" className="px-0" onClick={() => this.handleRegisterUser('login')}>
                          Login
                        </CButton>
                      </CCol>
                    </CRow>
                 
                    </div>

   
                    <span>
                      <p style={customCss.footerCss}>Already have an account ? <a style={handleType} onClick={() => this.handleRegisterUser('login')}><b  >Login</b></a> </p>
                    {/* <p>If don't have account You can <a style={handleType} onClick={() => this.handleRegisterUser('register')}><b  >Register</b></a> new user with <b>Us</b> !</p> */}
                    </span>

                    
            </Fragment>
          ) 

          }

        </CForm>
      </div>
    );
  }
}



export function mapStateToProps(state) {
  return {
    isAttemptingLogin: state.loginReducer.isAttemptingLogin,
    loginPayload: state.loginReducer.payload,
    loginError: state.loginReducer.loginError,
  };
}
export function mapDispatchToProps(dispatch) {
  return {
    login: (email, password) => dispatch(attemptLogin(email, password)),
  };
}



export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage);

