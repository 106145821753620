import React, { Fragment } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CButton,
  CCol,
  CForm,
  CRow,
  CAlert,
} from "@coreui/react";
import { InvertContentLoader } from "../../Comman/components";


const key = {
  marginBottom: '24px',
  marginLeft: '35%'
}

const key_text = {
  marginLeft: '191px'
}
const token_key = {
  color: '#1b1616',
  backgroundColor: '#d2d2d2',
  borderColor: '#d2d2d2'
}

export const SecretKeyForm = (props) => {
  const {
    handleGenerateSecretKey,
    isGenrating,
    errorMsg,
    secret_key,
    handleOpenApis
  } = props;
  return (
    <Fragment>
      <CCard>
        <CCardHeader>

          <b style={{ fontSize: "20px" }}>Generate Secret Key</b>
        </CCardHeader>
        <CCardBody>
          <span>
            {errorMsg && (
              <CAlert color="danger" className="msg_div">
                {errorMsg}
              </CAlert>
            )}
          </span>


          <CForm>
            <CRow>
            <CCol md="2" xs="2" >
              </CCol>
              <CCol md="8" xs="8" >
                <span>
                  <h4>  <small style={key_text}>Before you access our api's you need to create secret key </small></h4>
                </span>
                <CButton color="primary" className="px-0 secret_key" onClick={handleGenerateSecretKey} style={key}>
                  Generate Secret Key {isGenrating && <InvertContentLoader />}
                </CButton>
              </CCol>
              <CCol md="2" xs="2" >
              </CCol>
            </CRow>
          </CForm>
          {secret_key && (
            <Fragment>
              {/* <CAlert color="success" className="msg_div" style={token_key}>
                <span>
                  {secret_key}
                </span>
              </CAlert> */}
             <span>
             <span style={{ marginLeft: '31%' }} >
                Great, By using this secret key you can access our api's in your application
              </span>
              <br />
              <span className="open_api">
                <a  onClick={handleOpenApis}>Click  here to open api's</a>
              </span>
             </span>

            </Fragment>
          )}

        </CCardBody>
      </CCard>
    </Fragment>
  );
};
