import React, { Fragment } from "react";
import { CToast, CToastBody, CToastHeader, CToaster } from "@coreui/react";

export const ShowToastMessage = (props) => {
    const { success, header, content, type, handleCloseMessage } = props;
    console.log('message = ', header)
    return (
        <Fragment>
            <CToaster position={"top-right"} style={{ marginTop: "46px" }}>
                <CToast
                    show={true}
                    // autohide={50000}
                    fade={false}

                    className={`${success ? "call_success" : "call_fail"}`}
                // style={isError ? {backgroundColor:'rgb(210 87 40)', color:'antiquewhite'} : {backgroundColor:'rgb(86 140 208)', color:'antiquewhite'}}
                >
                    <CToastHeader
                        onClick={() => {
                            console.log('close taost')
                            handleCloseMessage(type);

                        }}
                        closeButton={true}
                        // className={
                        //   isSuccess
                        //     ? "success_msg"
                        //     : isError
                        //     ? "error_class"
                        //     : "toast_class"
                        // }
                        className={`${success ? "call_success" : "call_fail"}`}
                    // style={isError ? {backgroundColor:'rgb(210 87 40)', color:'antiquewhite'} : toast_header}
                    >
                        <span>{header}</span>
                    </CToastHeader>
                    <CToastBody>
                        <span>
                            {content}

                        </span>
                    </CToastBody>
                </CToast>
            </CToaster>
        </Fragment>
    );
};
