import React, { Component, Fragment } from 'react'
import { getSecretKey } from '../../Comman/functions'
import {ApiManageUI} from '../components/index'
import {generateSecretKey, getSecretKeyAPI} from '../services/common'
import {
    CButton,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CRow,
  } from "@coreui/react";


class ApiManage extends Component {
    state = {
        isGenrating:false,
        secret_key:'',
        errorMsg:'',
        successMsg:'',
        secretKeyObj:'',
        keyGenrated:false,
        secret_url:''
    }


    componentDidMount() {
        this.setState({
            fetchingData:true
        })
        this.fetchSecretKey();
        // const secret_key = getSecretKey("");
        // let secretKeyObj = "";
        // if (secret_key) {
        //     secretKeyObj = JSON.parse(secret_key);
        // }


        // this.setState({
        //    secret_key: secretKeyObj ? secretKeyObj.secret_key : null,
        //     secretKeyObj
        // })
    }

    /**
     * Fetch generated secret key api
     */
    fetchSecretKey = () => {
        getSecretKeyAPI().then(response => {
            console.log('getSecretKey = ', response.data.data)
            if (response.data.status == 200 ) {
                let secretKeyObj = response.data.data;
                this.setState({
                    secret_key: secretKeyObj ? secretKeyObj.secret_key : null,
                    secretKeyObj,
                    fetchingData:false
                 })
            } else {
                this.setState({ 
                    errorMsg : response.data.msg, 
                    fetchingData:false
                })
            } // End if-else
        }).catch(err => {
            console.log('getSecretKey error = ', err)
            console.log('response error = ', err)
            const error = err.response ? err.response.data.msg : "";
            this.setState({ 
                errorMsg : error,
                isGenrating: false,
                keyGenrated:false,
                fetchingData:false
            })
        })
    }

    /**
     * Handle generate secret key
     */
    handleGenerateSecretKey = () => {
        this.setState({ isGenrating: true })
        // Generate secret Api 
        generateSecretKey().then(response => {
            console.log('response secrete key = ', response)
            if(response.data.status == 200) {
                this.setState({ 
                    secret_key : response.data.data.secret_key, 
                    secret_url : response.data.data.key_url, 
                    isGenrating: false ,
                    keyGenrated:true
                })

                this.fetchSecretKey();
            } else {
                this.setState({ 
                    errorMsg : response.data.msg,
                    isGenrating: false,
                    keyGenrated:false
                })
            }
           
        }).catch(err => {
            console.log('response error = ', err)
            const error = err.response ? err.response.data.msg : "";
            this.setState({ 
                errorMsg : error,
                isGenrating: false,
                keyGenrated:false
            })
        })
        console.log('Generating = ')
    } // End function


    handleOpenApis = () => {
        console.log('handleOpenApis')
        const newWindow = window.open('http://developer.svslabs.us/docs/', '_blank')
    }

    handleCancelModal = () => {
        this.setState({
            keyGenrated:false
        })
    }

    /**
     * Download file 
     */
    downloadFile = async () => {
        const {secret_url, secret_key} = this.state; 

        const file_body = {
            'key_id': secret_key,
            'secret_key': secret_key,
        }
        const fileName = "secret_key_details";
        const json = JSON.stringify(file_body);
        const blob = new Blob([json],{type:'application/json'});
        const href = await URL.createObjectURL(blob);
        const link = document.createElement('a');
        const body = 'json file hewe';
        link.href = href;
        link.download = fileName + ".json";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        this.fetchSecretKey();
      } // End function


      /**
       * Fetch refresh data
       */
      refreshSecretKeyApi = () => {
          this.setState({
            fetchingData:true
          });

        // Call refresh data api
        this.fetchSecretKey();
      } // End function
    render() {
        const {isGenrating, errorMsg, secret_key, secretKeyObj, keyGenrated, fetchingData} = this.state;;
        return (
            <Fragment>
                <ApiManageUI
                    handleGenerateSecretKey={this.handleGenerateSecretKey}
                    isGenrating={isGenrating}
                    errorMsg={errorMsg}
                    secret_key={secret_key}
                    handleOpenApis={this.handleOpenApis}
                    secretKeyObj={secretKeyObj}
                    refreshSecretKeyApi={this.refreshSecretKeyApi}
                    fetchingData={fetchingData}
                />
                {keyGenrated && (
                    <CModal show={keyGenrated} onClose={this.handleCancelModal} color="primary"   size="lg">
                        <CModalHeader closeButton>
                            <CModalTitle style={{ color: "white" }}>New Key</CModalTitle>
                        </CModalHeader>
                        <CModalBody>
                            <form>
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Secret Key</label>
                                    <input type="text" value={secret_key} class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
                                        <small id="emailHelp" class="form-text text-muted download_key">
                                            <a onClick={this.downloadFile} download> Download key details </a>
                                        </small>
                                </div>
                            </form>
                        </CModalBody>
                        <CModalFooter>
                            {/* <CButton color="danger" onClick={this.handleDeleteConfirm}>
                            Delete {isLoading && ( <InvertContentLoader /> )}
                            </CButton>{" "} */}
                            <CButton color="primary" onClick={this.handleCancelModal}>
                            Okay
                            </CButton>
                        </CModalFooter>
                    </CModal>
                )}
            </Fragment>
        )
    }
}

export default ApiManage