export const ATTEMPT_LOGIN = 'ATTEMPT_LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const RESET_LOGIN = 'RESET_LOGIN';

export function attemptLogin(email, password, captchaToken) {
  return {
    type: ATTEMPT_LOGIN,
    email,
    password,
    captchaToken,
  };
}


export const LOGOUT = 'LOGOUT';
export const LOGOUT_ACTION = 'LOGOUT_ACTION';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export function logout() {
  return { type: LOGOUT_ACTION };
}



