import React, { Component } from "react";
import {
  CButton,
  CCol,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
  CAlert, 
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import "../../containers/common.css";
import {
  REGEX__EMAIL,
  TEXT__VALIDATION_ERROR__USERNAME,
} from "../../../Comman/constants";
import { ContentLoading, ShowToastMessage, InvertContentLoader } from "../../../Comman/components";
import { forgotPassword } from "../../servicers/common";

export class Forgot extends Component {
  state = {
    username: "",
    usernameError: "",
    errorMsg: "",
    loading: false,
    successMsg: "",
  };

  validateInput = (e) => {
    const field = e.target.name;
    const val = e.target.value;
    switch (field) {
      case "username":
        this.validateUsername(val);
        break;

      default:
        console.warn("validateInput was supplied an uncaught field case");
        break;
    }
  };

  /**
   * Validate input username
   */
  validateUsername = (val) => {
    if (!REGEX__EMAIL.test(val)) {
      this.setState({ usernameError: TEXT__VALIDATION_ERROR__USERNAME });
      return true;
    }
    this.setState({ usernameError: "" });
    return false;
  };

  /**
   * Validate input username and password
   */
  validateRequired = () => {
    const usernameCheck = this.validateUsername(this.state.username);

    if (usernameCheck) {
      return true;
    }
    return false;
  };

  /**
   * Handle inputs
   */
  handleChangeInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  /**
   * Submit login form
   */
  submitForm = (e) => {
    e.preventDefault();

    // proceed if no validation errors
    const { username, password } = this.state;
    const result = this.validateRequired();

    if (!result) {
      this.setState({ loading: true });
      const data = {
        email: username,
      };

      forgotPassword(data)
        .then((res) => {
          console.log("Success response = ", res.data);

          if (res.data && res.data.status == 200) {
            this.setState({
              successMsg: res.data.msg,
              loading: false,
              errorMsg: "",
            });
          } else {
            this.setState({
              errorMsg: res.data.msg,
              loading: false,
              successMsg: "",
            });
          }
        })
        .catch((err) => {
          console.log("error reponse = ", err.response);
          this.setState({
            errorMsg: err.response ? err.response.data.msg : "",
            loading: false,
            successMsg: "",
          });
        });
    }
  };

  render() {
    const {
      username,
      usernameError,
      errorMsg,
      loading,
      successMsg,
    } = this.state;
    const { handleLoginPage } = this.props;

    return (
      <div>
        <CForm>
          <h1>Forgot</h1>

          <p className="text-muted">Forgot your password</p>
          {errorMsg && (
            <CAlert color="danger">
              {/*eslint-disable-next-line*/}
              {`Error : ${errorMsg}`}
            </CAlert>
            // <ShowToastMessage
            //     success={false}
            //     header={'Error'}
            //     content={errorMsg}
            // />
          )}
          {successMsg && (
            // <ShowToastMessage
            //     success={true}
            //     header={'Success'}
            //     content={successMsg}
            // />
            <CAlert color="success">
              {/*eslint-disable-next-line*/}
              {`Success : ${successMsg}`}
            </CAlert>
          )}
          <CInputGroup className="mb-3 input_class">
            <CInputGroupPrepend>
              <CInputGroupText>
                <CIcon name="cil-user" />
              </CInputGroupText>
            </CInputGroupPrepend>
            <CInput
              type="text"
              placeholder="Username"
              name="username"
              autoComplete="username"
              onChange={this.handleChangeInput}
              value={username}
              onBlur={(e) => {
                if (username.length > 0) {
                  this.validateInput(e);
                }
              }}
            />
          </CInputGroup>
          <div
            className="validationErrorMessagesStyles"
            style={{ textAlign: "left" }}
          >
            {usernameError}
          </div>

          <CRow>
            <CCol xs="6">
              <CButton
                color="primary"
                className="px-4 login_bt"
                onClick={this.submitForm}
              >
                Forgot
                {loading && <InvertContentLoader />}
              </CButton>
            </CCol>
            <CCol xs="6" className="text-right">
              <CButton color="link" className="px-0" onClick={handleLoginPage}>
                Login
              </CButton>
            </CCol>
          </CRow>
        </CForm>
      </div>
    );
  }
}

// export default ForgotPage;
