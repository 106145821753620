export const FETCH_SALE_AGENTS = 'FETCH_SALE_AGENTS';
export const FETCH_SALE_AGENTS_SUCCESS = 'FETCH_SALE_AGENTS_SUCCESS';
export const FETCH_SALE_AGENTS_FAILURE = 'FETCH_SALE_AGENTS_FAILURE';


export function fetchSaleAgents() {
  return { type: FETCH_SALE_AGENTS };
}



