import React, { Component, Fragment } from 'react';
import { take, put, call, all } from 'redux-saga/effects';
import * as actions from '../actions';
import axios, { setAuthHeader } from '../../Comman/axiosConfig';
// import { SHOW_TOAST } from '../../Common/actions';
// import { CONST_LANG } from '../../Common/constant_language';
import { URL_HOST } from '../../Comman/constants'




export const fetchVerificationCallsData = async () => {
    try {
      const response = await axios.get(`${URL_HOST}/dashboard/total-verification-calls-attempted`);

    //   setAuthHeader(response.data.access_token);
      // setCookie('ytel_token', response.data.access_token, 7);
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  };
  

  export function* getVerificationCalls() {
    try {
      const response = yield fetchVerificationCallsData();

      if (response && response.status == 200) {

        yield all([
          put({
            type: actions.FETCH_VERIFICATION_CALLS_SUCCESS, 
            response: response.data,
          })
       
        ]);
      } else {
  
        yield all([
          put({
            type: actions.FETCH_VERIFICATION_CALLS_FAILURE,
            response,

          }),
          put({
            type: 'ERROR',
            msgType: 'error',
            header: (response && response.msg) ? response.msg : 'CONST_LANG.LOGIN_ERROR_CONTENT', //CONST_LANG.ERROR_HEADER,
            content: 'errorMessageContent',
          }),
        ]);
      }
    } catch (err) {
      yield all([
        put({ type: actions.FETCH_VERIFICATION_CALLS_FAILURE, err }),
        put({
          type: 'ERROR',
          msgType: 'error',
          header: 'Error',
          content: 'CONST_LANG.LOGIN_FAIL_MSG',
        }),
      ]);
    }
  }



  export function* watchVerificationCallsSaga() {
    while (true) {
      const state = yield take(actions.FETCH_VERIFICATION_CALLS);
      yield call(getVerificationCalls);
    }
  }
  