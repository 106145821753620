import React, { Fragment } from "react";
import "../../containers/common.css";
import {
  CAlert,
  CButton,
  CCol,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { ContentLoading, InvertContentLoader } from "../../../Comman/components";


export const VerifiedEmail = (props) => {
  const {
    code,
    validateInput,
    usernameError,
    errorMsg,
    resetMsg,
    handleLoginPage,
    handleChangeInput,
    submitForm,
    loading,
    isChange
  } = props;
  return (
    <Fragment>
      <CForm>
        <h1>Verify Account</h1>

        <p className="text-muted">Enter verify code from your email !</p>
        {errorMsg && (
          <CAlert color="danger">
            {/*eslint-disable-next-line*/}
            {`Error : ${errorMsg}`}
          </CAlert>
        )}
        {resetMsg && (
          <CAlert color="success">
            {/*eslint-disable-next-line*/}
            {`Success : ${resetMsg} you can login with new password `}
            <CButton color="link" className="px-0" onClick={handleLoginPage}>
              {" "}
              Click here{" "}
            </CButton>
          </CAlert>
        )}
        <CInputGroup className="mb-3 input_class">
          <CInputGroupPrepend>
            <CInputGroupText>
              <CIcon name="cil-user" />
            </CInputGroupText>
          </CInputGroupPrepend>
          <CInput
            type="number"
            placeholder="Enter Code"
            name="code"
            autoComplete="code"
            onChange={handleChangeInput}
            value={code}
            // onBlur={(e) => {
            //   if (code.length > 0) {
            //     validateInput(e);
            //   }
            // }}
          />
        </CInputGroup>
        <div
          className="validationErrorMessagesStyles"
          style={{ textAlign: "left" }}
        >
          {usernameError}
        </div>
    
        <CRow>
          <CCol xs="6">
            <CButton
              color="primary"
              className="px-4 login_bt"
              onClick={submitForm}
              disabled={isChange ? false : true}
            >
              Verify
              {loading && <InvertContentLoader />}
            </CButton>
          </CCol>
        </CRow>
      </CForm>
    </Fragment>
  );
};
