import React, { Component, Fragment } from 'react'
import { getSecretKey } from '../../Comman/functions'
import {ApiDocumentationUI} from '../components/index'
import {generateSecretKey} from '../services/common'
import {PageNotFound} from '../../Comman/components/index'
import {
    CCardBody,
    CCardHeader,
    CBadge,
    CCollapse,
    CTabs,
    CNav,
    CNavItem, 
    CNavLink,
    CTabContent,
    CTabPane,
  } from "@coreui/react";


class ApiDocumentation extends Component {
    state = {
        isGenrating:false,
        secret_key:'',
        errorMsg:'',
        successMsg:''
    }


    componentDidMount() {
       let secret_key =  getSecretKey();
       this.setState({secret_key: secret_key ? secret_key : null})
    }


    render() {
        const {isGenrating, errorMsg, secret_key} = this.state;;
        return (
            <Fragment>
                <CTabs>
                    <CNav variant="tabs">
                        <CNavItem>
                            <CNavLink>configuration</CNavLink>
                        </CNavItem>
                        <CNavItem>
                            <CNavLink>API Key</CNavLink>
                        </CNavItem>
                    </CNav>
                    <CTabContent>
                        <CTabPane>
                            <PageNotFound />
                        </CTabPane>
                        <CTabPane>
                            <ApiDocumentationUI />
                        </CTabPane>
                    </CTabContent>
                </CTabs>
                    
            </Fragment>
        )
    }
}

export default ApiDocumentation