// import { configureScope } from '@sentry/browser';
import * as ActionTypes from "../actions";

export const defaultState = {
  isLoading: false,
  roles: [],
};

export function FetchRolesReducer(state = defaultState, action) {
  switch (action.type) {
    case ActionTypes.FETCH_ROLES:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.FETCH_ROLES_SUCCESS: {
       return {
        ...state,
        isLoading: false,
        roles: action.response,
      };
    }
    case ActionTypes.FETCH_ROLES_FAILURE:
      return {
        ...state,
        isLoading: false,
        roles: null,
      };


    default:
      return state;
  }
}
