// import { configureScope } from '@sentry/browser';
import * as ActionTypes from "../actions";

export const defaultState = {
  isLoading: false,
  branches: [],
};

export function fetchBranchesReducer(state = defaultState, action) {
  switch (action.type) {
    case ActionTypes.FETCH_BRANCHES:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.FETCH_BRANCHES_SUCCESS: {
       return {
        ...state,
        isLoading: false,
        branches: action.response,
      };
    }
    case ActionTypes.FETCH_BRANCHES_FAILURE:
      return {
        ...state,
        isLoading: false,
        branches: null,
      };


    default:
      return state;
  }
}
