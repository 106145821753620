import { take, put, call, all } from 'redux-saga/effects';
import * as actions from '../../actions/EndUser';
import axios from '../../../Comman/axiosConfig';
import { URL_HOST } from '../../../Comman/constants'



export const fetchSaleAgentsSaga = async () => {
    try {
      const response = await axios.get(`${URL_HOST}/common/sales_agents`);

      return response.data;
    } catch (err) {
      return err.response.data;
    }
  };
  

  export function* fetchSaleAgent() {
    try {
      const response = yield fetchSaleAgentsSaga();
      if (response && response.status == 200) {

        yield all([
          put({
            type: actions.FETCH_SALE_AGENTS_SUCCESS, 
            response,
          })
       
        ]);
      } else {
  
        yield all([
          put({
            type: actions.FETCH_SALE_AGENTS_FAILURE,
            response,

          })
        ]);
      }
    } catch (err) {
      yield all([
        put({ type: actions.FETCH_SALE_AGENTS_FAILURE, err })
       
      ]);
    }
  }



  export function* watchFetchSaleAgentsSaga() {
    while (true) {
      const state = yield take(actions.FETCH_SALE_AGENTS);
      yield call(fetchSaleAgent);
    }
  }
  