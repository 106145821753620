// import { configureScope } from '@sentry/browser';
import * as ActionTypes from "../../actions/EndUser";

export const defaultState = {
  isLoading: false,
  sale_agents: [],
  team_leads: [],
  languages: [],
  loan_types: [],
  endUsers : []
};

export function fetchEndUserReducer(state = defaultState, action) {
  switch (action.type) {
    case ActionTypes.FETCH_LANGUAGES:
      return {
        ...state,
        isLoading: true,
        languages:[]
      };

    case ActionTypes.FETCH_LANGUAGES_SUCCESS: 
       return {
        ...state,
        isLoading: false,
        languages: action.response.data,
      };
    
    case ActionTypes.FETCH_LANGUAGES_FAILURE:
      return {
        ...state,
        isLoading: false,
        languages: [],
      };

    case ActionTypes.FETCH_LOAN_TYPES:
        return {
            ...state,
            isLoading: true,
            loan_types:[]
        };

    case ActionTypes.FETCH_LOAN_TYPES_SUCCESS: 
        return {
            ...state,
            isLoading: false,
            loan_types: action.response.data,
        };
   
    case ActionTypes.FETCH_LOAN_TYPES_FAILURE:
        return {
            ...state,
            isLoading: false,
            loan_types: [],
        };
  
    case ActionTypes.FETCH_SALE_AGENTS:
        return {
            ...state,
            isLoading: true,
            sale_agents:[]
        };

    case ActionTypes.FETCH_SALE_AGENTS_SUCCESS: 
        return {
            ...state,
            isLoading: false,
            sale_agents: action.response.data,
        };
    
    case ActionTypes.FETCH_SALE_AGENTS_FAILURE:
        return {
            ...state,
            isLoading: false,
            sale_agents: [],
        }; 

    case ActionTypes.FETCH_TEAM_LEADS:
        return {
            ...state,
            isLoading: true,
            team_leads:[]
        };

    case ActionTypes.FETCH_TEAM_LEADS_SUCCESS: 
        return {
            ...state,
            isLoading: false,
            team_leads: action.response.data,
        };
    
    case ActionTypes.FETCH_TEAM_LEADS_FAILURE:
        return {
            ...state,
            isLoading: false,
            team_leads: [],
        }; 

    case ActionTypes.FETCH_END_USER:
        return {
            ...state,
            isLoading: true,
            endUsers:[]
        };

    case ActionTypes.FETCH_END_USER_SUCCESS: 
        return {
            ...state,
            isLoading: false,
            endUsers: action.response.data,
        };
    
    case ActionTypes.FETCH_END_USER_FAILURE:
        return {
            ...state,
            isLoading: false,
            endUsers: [],
        }; 

    case ActionTypes.HANDLE_CALL_STATUS:
        return {
            ...state,
            isLoading: true,
            calls:[]
        };

    case ActionTypes.HANDLE_CALL_STATUS_SUCCESS: 
        return {
            ...state,
            isLoading: false,
            endUsers: action.response.data,
        };

    case ActionTypes.HANDLE_CALL_STATUS_FAILURE:
        return {
            ...state,
            isLoading: false,
            calls: [],
        }; 
    default:
      return state;
  }
}
