import React, { Fragment } from "react";
import { SecretKeyForm } from "./SecretKeyForm";
import {
  CCard,
  CCardBody,
  CTabs,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
} from "@coreui/react";
import { ApiDocumentationUI } from "./ApiDocumentationUI";
import { KeyConfiguration } from "./KeyConfiguration";

export const ApiManageUI = (props) => {
  const {
    handleGenerateSecretKey,
    isGenrating,
    errorMsg,
    secret_key,
    handleOpenApis,
    secretKeyObj,
    refreshSecretKeyApi,
    fetchingData
  } = props;
  return (
    <Fragment>
      <CCard>
        {/* <CCardHeader>
            
        <b style={{ fontSize: "20px" }}> Open API's</b>
           </CCardHeader> */}
        <CCardBody>


          <CTabs>
            <CNav variant="tabs">
              <CNavItem>
                <CNavLink>Configuration</CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink>API Key</CNavLink>
              </CNavItem>

            </CNav>
            <CTabContent>
              <CTabPane>
                <span style={{ marginTop: 20 }}>
                  {secret_key ? (
                    <KeyConfiguration
                      secretKeyObj={secretKeyObj}
                      refreshSecretKeyApi={refreshSecretKeyApi}
                      fetchingData={fetchingData}
                    />
                  ) : (
                    <ApiDocumentationUI />
                  )}

                </span>
              </CTabPane>
              <CTabPane>
                <SecretKeyForm
                  handleGenerateSecretKey={handleGenerateSecretKey}
                  isGenrating={isGenrating}
                  errorMsg={errorMsg}
                  secret_key={secret_key}
                  handleOpenApis={handleOpenApis}
                />

              </CTabPane>
            </CTabContent>
          </CTabs>



          {/* <PageNotFound /> */}
        </CCardBody>
      </CCard>
    </Fragment>
  );
};
