import axios from "axios";
import {URL_HOST} from '../../Comman/constants'
// const domain = "http://sapna-dev.turant.com:8000";

export const login = (data) => axios.post(`${URL_HOST}/auth/login`, data);

export const logout = () => axios.get(`${URL_HOST}/auth/logout`);

// export const logout = () => ({
//   url: `${URL_HOST}/auth/logout`,
//   headers: { Authorization: "Bearer " + localStorage.getItem("access_token") },
// });

export const bankLists = () =>
axios.get(`${URL_HOST}/banks/all`);

export const forgotPassword = (data) =>
  axios.post(`${URL_HOST}/auth/forgot-password`, data);

export const resetPassword = (data) =>
  axios.post(`${URL_HOST}/auth/reset-password`, data);



  export const registerUser = (data) =>
  axios.post(`${URL_HOST}/auth/register-user`, data);


  export const validUser = (id, data) =>
  axios.post(`${URL_HOST}/auth/verify-user/${id}`, data);

  
  export const resendActivationLinkAPI = (data) =>
  axios.post(`${URL_HOST}/auth/resend-activation-mail`, data);
