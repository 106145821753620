import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import rooteReducers from "./reducers/rooteReducers";
import sagas from "./sagas";

const initialState = {
  sidebarShow: "responsive",
};

const sagaMiddleware = createSagaMiddleware();

const middleware = applyMiddleware(sagaMiddleware);

const store = createStore(
  rooteReducers,
  compose(
    middleware,
    window.devToolsExtension ? window.devToolsExtension() : (f) => f
  )
);

sagaMiddleware.run(sagas);

export default store;
