import axios from "axios";
import {URL_HOST} from '../../Comman/constants'


export const getSecretKeyAPI = () =>
axios.get(`${URL_HOST}/mobile/get-key`);



export const generateSecretKey = () =>
axios.get(`${URL_HOST}/mobile/generate-key`);

