import React, { Component, Fragment } from 'react';
import { take, put, call, all ,delay } from 'redux-saga/effects';

import * as actions from '../actions';
import axios, { setAuthHeader } from '../../Comman/axiosConfig';
// import { SHOW_TOAST } from '../../Common/actions';
// import { CONST_LANG } from '../../Common/constant_language';
import { URL_HOST } from '../../Comman/constants'


export const profileSaga = async (id, data) => {
    try {
      const response = await axios.post(`${URL_HOST}/profile/update/${id}`, data);

    //   setAuthHeader(response.data.access_token);
      // setCookie('ytel_token', response.data.access_token, 7);
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  };
  

  export function* profileSagas(id, data) {
    try {
      const response = yield profileSaga(id, data);

      if (response && response.status == 200) {

        yield all([
          put({
            type: actions.PROFILE_SUCCESS, 
            response,
          })
       
        ]);
  
      
        yield delay(5000);
        yield put({type: actions.PROFILE_SUCCESS_HIDE });

      } else {
  
        yield all([
          put({
            type: actions.PROFILE_FAILURE,
            response,

          }),
          put({ 
            type: 'ERROR',
            msgType: 'error',
            header: (response && response.msg) ? response.msg : 'CONST_LANG.LOGIN_ERROR_CONTENT', //CONST_LANG.ERROR_HEADER,
            content: 'errorMessageContent',
          }),
        ]);
      }
    } catch (err) {
      yield all([
        put({ type: actions.PROFILE_FAILURE, err }),
        put({
          type: 'ERROR',
          msgType: 'error',
          header: 'Error',
          content: 'CONST_LANG.LOGIN_FAIL_MSG',
        }),
      ]);
    }
  }



  export function* watchProfileSaga() {
    while (true) {
      const state = yield take(actions.PROFILE_REQUEST);
      yield call(profileSagas, state.id, state.data);
    }
  }
  