export const PROFILE_REQUEST = 'PROFILE_REQUEST';
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
export const PROFILE_FAILURE = 'PROFILE_FAILURE';
export const PROFILE_SUCCESS_HIDE = 'PROFILE_SUCCESS_HIDE';


export function updateProfile(id, data) {
  return { type: PROFILE_REQUEST, id, data };
}



