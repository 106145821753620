import React, { Component, Fragment } from 'react';
import { take, put, call, all } from 'redux-saga/effects';
import * as actions from "../../actions/EndUser";
import axios, { setAuthHeader } from '../../../Comman/axiosConfig';
// import { SHOW_TOAST } from '../../Common/actions';
// import { CONST_LANG } from '../../Common/constant_language';
import { URL_HOST } from '../../../Comman/constants'




export const manageRegisterCallStatus = async (id, payload) => {
    try {
      const response = await axios.post(`${URL_HOST}/call/register/${id}`, payload);

    //   setAuthHeader(response.data.access_token);
      // setCookie('ytel_token', response.data.access_token, 7);
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  };
  
export const manageVerifyCallStatus = async (id, payload) => {
    try {
      const response = await axios.post(`${URL_HOST}/call/verify/register-call/${id}`, payload);

    //   setAuthHeader(response.data.access_token);
      // setCookie('ytel_token', response.data.access_token, 7);
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  };

  export function* callStatus(id, type, payload) {
    try {
        console.log('sction type id = ', id)
        console.log('sction type = ', type)
        console.log('sction type payload = ', payload)
        
        // if(type == 1) {
        //     const response = yield manageRegisterCallStatus(id, payload);
        //     console.log('login response = ', response)
        //   if (response && response.token) {
    
        //     yield all([
        //       put({
        //         type: actions.HANDLE_CALL_STATUS_SUCCESS, 
        //         response,
        //       })
           
        //     ]);
        //   } else {
      
        //     yield all([
        //       put({
        //         type: actions.HANDLE_CALL_STATUS_FAILURE,
        //         response,
    
        //       }),
        //       put({
        //         type: 'ERROR',
        //         msgType: 'error',
        //         header: (response && response.msg) ? response.msg : 'CONST_LANG.LOGIN_ERROR_CONTENT', //CONST_LANG.ERROR_HEADER,
        //         content: 'errorMessageContent',
        //       }),
        //     ]);
        //   }
        // } else if(type==2) {
        //     const response = yield manageVerifyCallStatus(id, payload);
        //     console.log('login response = ', response)
        //   if (response && response.token) {
    
        //     yield all([
        //       put({
        //         type: actions.HANDLE_CALL_STATUS_SUCCESS, 
        //         response,
        //       })
           
        //     ]);
        //   } else {
      
        //     yield all([
        //       put({
        //         type: actions.HANDLE_CALL_STATUS_FAILURE,
        //         response,
    
        //       }),
        //       put({
        //         type: 'ERROR',
        //         msgType: 'error',
        //         header: (response && response.msg) ? response.msg : 'CONST_LANG.LOGIN_ERROR_CONTENT', //CONST_LANG.ERROR_HEADER,
        //         content: 'errorMessageContent',
        //       }),
        //     ]);
        //   }
        // }

    } catch (err) {
      yield all([
        put({ type: actions.HANDLE_CALL_STATUS_FAILURE, err }),
        put({
          type: 'ERROR',
          msgType: 'error',
          header: 'Error',
          content: 'CONST_LANG.LOGIN_FAIL_MSG',
        }),
      ]);
    }
  }



  export function* watchHandleCallStatus() {
    while (true) {
      const state = yield take(actions.HANDLE_CALL_STATUS);
      yield call(callStatus,  state.id, state.type, state.payload);
    }
  }