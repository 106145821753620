import React from 'react'
import {
  CButton,
  CCol,
  CContainer,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupAppend,
  CInputGroupText,
  CRow 
} from '@coreui/react'
import CIcon from '@coreui/icons-react'

export const ApiDocumentationUI = () => { 
  return (
    <div className="c-app c-default-layout flex-row align-items-center" style={{marginTop:'0px !important'}}>
      <CContainer>
        <CRow className="justify-content-center" style={{textAlign: 'center'}}>
          <CCol md="6">
            <div className="clearfix">
              {/* <h1 className="float-left display-3 mr-4">404</h1> */}
              {/* <h4 className="pt-3">Oops! You{'\''}re lost.</h4> */}
              {/* <h4 className="pt-3">Oops! We are under construction.</h4>
              <p className="text-muted float-left">The page you are looking for was not found.</p> */}
              <h4 className="pt-3">This Page is Under Development ( API Documentation ) !</h4>

            </div>
            {/* <CInputGroup className="input-prepend">
              <CInputGroupPrepend>
                <CInputGroupText>
                  <CIcon name="cil-magnifying-glass" />
                </CInputGroupText>
              </CInputGroupPrepend>
              <CInput size="16" type="text" placeholder="What are you looking for?" />
              <CInputGroupAppend>
                <CButton color="info">Search</CButton>
              </CInputGroupAppend>
            </CInputGroup> */}
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}


