import { setAuthHeader } from './axiosConfig';


/**
 * Handle after logout page
 */
export function redirectToLoginPage(logout = false) {
  if (logout) {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
    localStorage.removeItem("access_token");
    localStorage.removeItem("username");
    localStorage.removeItem("role");
    localStorage.removeItem("loggedInUser");
    localStorage.removeItem("secret_key");
    localStorage.removeItem("customer_id");
    localStorage.removeItem("permissions");
    
    redirectToDashboard(false);
  }
}

/**
 * Handle auth routes
 */
export function redirectToDashboard(priv) {
  if (priv) {
    window.location.hash = "/dashboard";
  } else {
    window.location.hash = "/login";
  }
}

/**
 * Get user token from storage
 */
export function getUserToken(name) {
  const t = localStorage.access_token;
  return t ? t : "";
}

/**
 * Get user permissions from storage
 */
 export function getPermissions(name) {
  const t = localStorage.permissions;
  return t ? t : [];
}

/**
 * Set user token to storage
 */
export function settToken(token) {
  localStorage.setItem("access_token", token);
  setAuthHeader();
}


/**
 * Set user permissions to storage
 */
 export function setPermission(permissions) {
  localStorage.setItem("permissions", permissions);
  setAuthHeader();
}


/**
 * Set auth user role to storage
 */
export function settUserObj(user) {
  localStorage.setItem("role", user);
}

/**
 * Set auth user to storage
 */
export function setLoggedInUser(user) {
  localStorage.setItem("loggedInUser", user);
}

/**
 * Set auth user to storage
 */
export function getLoggedInUser() {
  return localStorage.loggedInUser;
}

/**
 * Set auth user to storage
 */
export function getUserObj() {
  return localStorage.role;
}

/**
 * Set user token to storage
 */
export function settUser(user) {
  localStorage.setItem("username", user);
}

/**
 * Get user token from storage
 */
export function getUsername(username) {
  const t = localStorage.username;
  return t ? t : "";
}



/**
 * Set secret key to storage
 */
 export function setSecretKey(key) {
  localStorage.setItem("secret_key", key);
}


/**
 * Get secret key to storage
 */
 export function getSecretKey() {
 return localStorage.secret_key;
}
