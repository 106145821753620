import React, { Component, Fragment } from 'react';
import { take, put, call, all } from 'redux-saga/effects';
import * as actions from '../actions';
import axios, { setAuthHeader } from '../../Comman/axiosConfig';
// import { SHOW_TOAST } from '../../Common/actions';
// import { CONST_LANG } from '../../Common/constant_language';
import { URL_HOST } from '../../Comman/constants'




export const fetchLogin = async (email, password) => {
    try {
      const response = await axios.post(`${URL_HOST}/auth/login`, {
        email, 
        password
      });

    //   setAuthHeader(response.data.access_token);
      // setCookie('ytel_token', response.data.access_token, 7);
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  };
  

  export function* loginUser(email, password) {
    try {
      const response = yield fetchLogin(email, password);
        console.log('login response = ', response)
      if (response && response.token) {

        yield all([
          put({
            type: actions.LOGIN_SUCCESS, 
            response,
          })
       
        ]);
      } else {
  
        yield all([
          put({
            type: actions.LOGIN_FAILURE,
            response,

          }),
          put({
            type: 'ERROR',
            msgType: 'error',
            header: (response && response.msg) ? response.msg : 'CONST_LANG.LOGIN_ERROR_CONTENT', //CONST_LANG.ERROR_HEADER,
            content: 'errorMessageContent',
          }),
        ]);
      }
    } catch (err) {
      yield all([
        put({ type: actions.LOGIN_FAILURE, err }),
        put({
          type: 'ERROR',
          msgType: 'error',
          header: 'Error',
          content: 'CONST_LANG.LOGIN_FAIL_MSG',
        }),
      ]);
    }
  }



  export function* watchLogin() {
    while (true) {
      const state = yield take(actions.ATTEMPT_LOGIN);
      yield call(loginUser, state.email, state.password);
    }
  }
  