

export const REGEX__EMAIL = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
export const TEXT__VALIDATION_ERROR__USERNAME =
  'Must provide a valid email address.';
  export const TEXT__VALIDATION_ERROR__PASSWORD_EMPTY =
  'Must provide a password.';

  export const TEXT__VALIDATION_ERROR__NAME_EMPTY =
  'Must provide a name.';

  export const TEXT__VALIDATION_ERROR__CONFIRM_PASSWORD_EMPTY =
  'Must provide a confirm password.';

  export const TEXT__VALIDATION_ERROR__CONFIRM_PASSWORD_UNMATCH =
  'Confirm password must match with password.';

  export const LOGOUT_WARNING_TIME = 240000; // warning after 4 min

  export const LOGOUT_TIME_OUT = 300000; // logout after 5 min

  // export const URL_HOST = 'https://api.mturbine.com/api'; //'http://api.mturbine.com/api';

  export const URL_HOST = "https://api.turant.world/api"; //'https://api.turant.world/api';


   //'https://api.mysvslabs.com' ;
   //https://devapi.svslabs.us/api/v1
   //'https://api.turant.world';
   //'http://sapna-dev.turant.com:8000';
  //https://34.66.47.11
  //http://api.svslabs.us
  //http://devapi.svslabs.us

  export const URL_HOST_PROFILE = "https://api.turant.world";


  // /var/www/anurag/storage/app/public/sounds/75561152_Sapna-Pastapure_01-05-2022%2012%3A39%3A16
  // /var/www/anurag/storage/app/public/sounds/56230235_Test-TEST_01-05-2022%2012%3A35%3A42

  export const ERROR_VALIDATIONS = {
    REGEX__EMAIL : /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
    TEXT__VALIDATION_ERROR__USERNAME : 'Must provide a valid email address.',
    TEXT__VALIDATION_ERROR__PASSWORD_EMPTY : 'Mandatory Field !',
    TEXT__VALIDATION_ERROR__CONFIRM_PASSWORD_EMPTY : 'Mandatory Field !',
    TEXT__VALIDATION_ERROR__CONFIRM_PASSWORD_UNMATCH :'Confirm password must match with password.',
    TEXT__VALIDATION_ERROR__USERNAME_EMPTY : 'Please fill username'
  }

  // https://www.naukri.com/myapply/saveApply?strJobsarr=061221007157&applytype=single&resId=136644483&ApplyMode=1&logstr=--simJobDeskACP-1-F-0-1--16392075647412432-&id=&src=&reqId=&response=&questData=&multiApplyResp={%22061221007157%22:200}&https_apply=&jquery=1&edup=&applySrc=simJobDeskACP&isMobile=&actualDomain=www.naukri.com&interest=&https_apply=&cb=&fb=&appreqid=&ip=103.208.71.58&webApp=&jobTitle=Software%20Developer%20-%20PHP
export const super_admin = ["Super Admin"];
export const admin = [ "Admin"];
export const customer_care = [ "Customer"];
export const employees = [ "Sales Agent", "Team Lead"];

export const ROUTE_VALIDATE = {
  IS_ADMIN  : true,
  IS_SUPER_ADMIN : true,
  IS_CUSTOMER_CARE  :true,
  IS_EMPLOYEE : true
}
