// import { configureScope } from '@sentry/browser';
import * as ActionTypes from "../actions";

export const defaultState = {
  isLoading: false,
  statuses: [],
};

export function FetchStatusReducer(state = defaultState, action) {
  switch (action.type) {
    case ActionTypes.FETCH_STATUS:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.FETCH_STATUS_SUCCESS: {
       return {
        ...state,
        isLoading: false,
        statuses: action.response,
      };
    }
    case ActionTypes.FETCH_STATUS_FAILURE:
      return {
        ...state,
        isLoading: false,
        statuses: null,
      };


    default:
      return state;
  }
}
