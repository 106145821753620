import { combineReducers } from 'redux';



import { loginReducer } from '../components/Auth/reducers/index';
import { FetchRolesReducer, fetchBranchesReducer, FetchStatusReducer } from '../components/Comman/reducers/index'
import {ProfileReducer} from '../components/Profile/reducers/index';
import { fetchEndUserReducer } from '../components/EndUser/reducers/index'
import { CustomerReducer } from '../components/Dashboard/reducers/index'

export  default combineReducers({
    loginReducer,
    FetchRolesReducer,
    fetchBranchesReducer,
    FetchStatusReducer,
    ProfileReducer,
    fetchEndUserReducer,
    CustomerReducer
})