import React, { Component } from "react";
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { attemptLogin } from '../Auth/actions/index'
import { fetchCustomerLoginDetails } from "./services/common";



class LoginSSO extends Component {
  state = {
  
  };



  componentDidMount() {
    // const { id } = this.props.match.params;
    const { id } = this.props.match.params;
    console.log('login this.props = ', id)
    const splite_id = id.split("-");
    const customer_id =  splite_id[0]
    const bank_id =  splite_id[1]
    console.log('login this.props = ', id)
    console.log('login this.props splite_id = ', splite_id)
    console.log('login this.props splite_id 0 = ', splite_id[0])
    console.log('login this.props splite_id 1 = ', splite_id[1])

    // Fetch login details
    fetchCustomerLoginDetails(bank_id)
                  .then(response => {
                    const res = response.data
                    console.log('login details = ', res)
                    if(res.status == 200) {
                      localStorage.setItem("customer_id", customer_id);
                      console.log('login details username = ', res.data.username)
                      const username =  res.data.username; //'globalfintechfest@exhibition.com';
                      const pass =res.data.password;
                      this.props.login(username, pass);
                    } else {  alert('error')  } // End if-else
                  })
                  .catch(err => {
                    const error = err.response ? err.response.data.msg : err;
                    console.log('login details error = ', error)
                    alert(error)
                  })

  }




  render() {
    const {
  
    } = this.state;

    return (
      <div>

      </div>
    );
  }
}



export function mapDispatchToProps(dispatch) {
  return {
    login: (email, password) => dispatch(attemptLogin(email, password)),
  };
}



export default connect(
  null,
  mapDispatchToProps
)(LoginSSO);

