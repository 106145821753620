import { take, put, call, all } from "redux-saga/effects";
import * as actions from "../../actions/EndUser";
import axios from "../../../Comman/axiosConfig";
import { URL_HOST } from "../../../Comman/constants";

export const fetchEndUsersSaga = async (login_type) => {
  try {
    console.log('isSaleAgentisSaleAgent fropm sage = ', login_type)
    const response = await axios.post(`${URL_HOST}/end_user/list`, {
      login_user  : login_type
    });

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export function* fetchEndUsers(login_type) {
  try {
    const response = yield fetchEndUsersSaga(login_type);
    if (response && response.status == 200) {
      yield all([
        put({
          type: actions.FETCH_END_USER_SUCCESS,
          response,
        }),
      ]);
    } else {
      yield all([
        put({
          type: actions.FETCH_END_USER_FAILURE,
          response,
        }),
      ]);
    }
  } catch (err) {
    yield all([put({ type: actions.FETCH_END_USER_FAILURE, err })]);
  }
}

export function* watchFetchEndUsersSaga() {
  while (true) {
    const state = yield take(actions.FETCH_END_USER);
    yield call(fetchEndUsers, state.login_type);
  }
}
