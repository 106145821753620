// import { configureScope } from '@sentry/browser';
import * as ActionTypes from "../actions";



export const defaultLoginState = {
  isTotalCustomerFatching:false,
  isTotalEnduserFatching:false,
  isTotalRecordsUploadFatching:false,
  isCallsFatching:false,
  isRegisterCallFatching:false,
  isVerifyCallsFatching:false,

  isTodayEnduserFatching:false,
  isTodayCustomerFatching:false,
  isTodayUploadedRecordsFatching:false,
  isTodayAttemptesCallsFatching:false,
  isTodayRegisterCallFatching:false,
  isTodayVerifyCallFatching:false,

  customersDetails:[],
  endUserDetails:[],
  uploadedRecords:[],
  attempted_calls : [],
  registration_calls:[],
  verification_calls:[],
  todays_customer:[],
  today_enduser:[],
  today_uploaded_records : [],
  today_attempts_call:[],
  today_register_calls:[],
  today_verification_calls:[],

};


export function CustomerReducer(state = defaultLoginState, action) {
  switch (action.type) {
    case ActionTypes.FETCH_CUSTOMER:
      return {
        ...state,
        isTotalCustomerFatching: true,
    
      };

    case ActionTypes.FETCH_CUSTOMER_SUCCESS: 
      return {
        ...state,
        isTotalCustomerFatching: false,
        customersDetails: action.response,
      };
      
    case ActionTypes.FETCH_CUSTOMER_FAILURE:
      return {
        ...state,
        isTotalCustomerFatching: false,
        customersDetails: action.response,
      };

    case ActionTypes.FETCH_ENDUSER:
      return {
        ...state,
        isTotalEnduserFatching: true,
      };

    case ActionTypes.FETCH_ENDUSER_SUCCESS: 
      return {
        ...state,
        isTotalEnduserFatching: false,
        endUserDetails: action.response,
      };
      
    case ActionTypes.FETCH_ENDUSER_FAILURE:
      return {
        ...state,
        isTotalEnduserFatching: false,
        endUserDetails: action.response,
      };
  
    case ActionTypes.FETCH_UPLOADERECORDS:
      return {
        ...state,
        isTotalRecordsUploadFatching: true,
      };

    case ActionTypes.FETCH_UPLOADERECORDS_SUCCESS: 
      return {
        ...state,
        isTotalRecordsUploadFatching: false,
        uploadedRecords: action.response,
      };
      
    case ActionTypes.FETCH_UPLOADERECORDS_FAILURE:
      return {
        ...state,
        isTotalRecordsUploadFatching: false,
        uploadedRecords: action.response,
      };
  
    case ActionTypes.FETCH_CALL_ATTEMPTED:
      return {
        ...state,
        isCallsFatching: true,
      };

    case ActionTypes.FETCH_CALL_ATTEMPTED_SUCCESS: 
      return {
        ...state,
        isCallsFatching: false,
        attempted_calls: action.response,
      };
      
    case ActionTypes.FETCH_CALL_ATTEMPTED_FAILURE:
      return {
        ...state,
        isCallsFatching: false,
        attempted_calls: action.response,
      };

    case ActionTypes.FETCH_RGISTRATION_CALLS:
      return {
        ...state,
        isRegisterCallFatching: true,
      };

    case ActionTypes.FETCH_RGISTRATION_CALLS_SUCCESS: 
      return {
        ...state,
        isRegisterCallFatching: false,
        registration_calls: action.response,
      };
      
    case ActionTypes.FETCH_RGISTRATION_CALLS_FAILURE:
      return {
        ...state,
        isRegisterCallFatching: false,
        registration_calls: action.response,
      };

    case ActionTypes.FETCH_VERIFICATION_CALLS:
      return {
        ...state,
        isVerifyCallsFatching: true,
      };

    case ActionTypes.FETCH_VERIFICATION_CALLS_SUCCESS: 
      return {
        ...state,
        isVerifyCallsFatching: false,
        verification_calls: action.response,
      };
      
    case ActionTypes.FETCH_VERIFICATION_CALLS_FAILURE:
      return {
        ...state,
        isVerifyCallsFatching: false,
        verification_calls: action.response,
      };
    

    case ActionTypes.FETCH_TODAY_ENDUSER:
      return {
        ...state,
        isTodayEnduserFatching: true,
      };

    case ActionTypes.FETCH_TODAY_ENDUSER_SUCCESS: 
      return {
        ...state,
        isTodayEnduserFatching: false,
        today_enduser: action.response,
      };
      
    case ActionTypes.FETCH_TODAY_ENDUSER_FAILURE:
      return {
        ...state,
        isTodayEnduserFatching: false,
        today_enduser: action.response,
      };
      

    case ActionTypes.FETCH_TODAY_CUSTOMER:
      return {
        ...state,
        isTodayCustomerFatching: true,
    
      };

    case ActionTypes.FETCH_TODAY_CUSTOMER_SUCCESS: 
      return {
        ...state,
        isTodayCustomerFatching: false,
        todays_customer: action.response,
      };
      
    case ActionTypes.FETCH_TODAY_CUSTOMER_FAILURE:
      return {
        ...state,
        isTodayCustomerFatching: false,
        todays_customer: action.response,
      };
      
  
    case ActionTypes.FETCH_TODAY_CALL_ATTEMPTED:
      return {
        ...state,
        isTodayAttemptesCallsFatching: true,
    
      };

    case ActionTypes.FETCH_TODAY_CALL_ATTEMPTED_SUCCESS: 
      return {
        ...state,
        isTodayAttemptesCallsFatching: false,
        today_attempts_call: action.response,
      };
      
    case ActionTypes.FETCH_TODAY_CALL_ATTEMPTED_FAILURE:
      return {
        ...state,
        isTodayAttemptesCallsFatching: false,
        today_attempts_call: action.response,
      };
      
      
    case ActionTypes.FETCH_TODAY_RGISTRATION_CALLS:
      return {
        ...state,
        isTodayRegisterCallFatching: true,
    
      };

    case ActionTypes.FETCH_TODAY_RGISTRATION_CALLS_SUCCESS: 
      return {
        ...state,
        isTodayRegisterCallFatching: false,
        today_register_calls: action.response,
      };
      
    case ActionTypes.FETCH_TODAY_RGISTRATION_CALLS_FAILURE:
      return {
        ...state,
        isTodayRegisterCallFatching: false,
        today_register_calls: action.response,
      };     
      
    
    case ActionTypes.FETCH_TODAY_UPLOADERECORDS:
      return {
        ...state,
        isTodayUploadedRecordsFatching: true,
    
      };

    case ActionTypes.FETCH_TODAY_UPLOADERECORDS_SUCCESS: 
      return {
        ...state,
        isTodayUploadedRecordsFatching: false,
        today_uploaded_records: action.response,
      };
      
    case ActionTypes.FETCH_TODAY_UPLOADERECORDS_FAILURE:
      return {
        ...state,
        isTodayUploadedRecordsFatching: false,
        today_uploaded_records: action.response,
      };     
    
    case ActionTypes.FETCH_TODAY_VERIFICATION_CALLS:
      return {
        ...state,
        isTodayVerifyCallFatching: true,
    
      };

    case ActionTypes.FETCH_TODAY_VERIFICATION_CALLS_SUCCESS: 
      return {
        ...state,
        isTodayVerifyCallFatching: false,
        today_verification_calls: action.response,
      };
      
    case ActionTypes.FETCH_TODAY_VERIFICATION_CALLS_FAILURE:
      return {
        ...state,
        isTodayVerifyCallFatching: false,
        today_verification_calls: action.response,
      };     
      
    default:
      return state;
  }
}

