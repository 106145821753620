import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";


const hostname = window.location.hostname;
console.log('firebase hos name = ', hostname)
if(hostname == 'mturbine.com') {
 // MT Firebase config
 var firebaseConfig = {
  
    apiKey: "AIzaSyB6NS8XqFtjPLA9mDQxOxFtTcC-Cn0ofjc",
    authDomain: "turant-ivr-settings.firebaseapp.com",
    databaseURL: "https://turant-ivr-settings-default-rtdb.firebaseio.com",
    projectId: "turant-ivr-settings",
    storageBucket: "turant-ivr-settings.appspot.com",
    messagingSenderId: "1045501731025",
    appId: "1:1045501731025:web:3debe861d3121563e7e5f0",
    measurementId: "G-BHSTJ0V1PH",
 }
} else if(hostname == 'turant.world') {
  var firebaseConfig = {
    apiKey: "AIzaSyCwotXxTZp521Uh42dK2eEZHrjr17pYB70",
    authDomain: "turant-prod-ivr-settings.firebaseapp.com",
    databaseURL: "https://turant-prod-ivr-settings-default-rtdb.firebaseio.com",
    projectId: "turant-prod-ivr-settings",
    storageBucket: "turant-prod-ivr-settings.appspot.com",
    messagingSenderId: "165550991206",
    appId: "1:165550991206:web:0aba574d7bcbfc1e698aa7",
    measurementId: "G-XK28TX99GS"
 }
} else if(hostname == 'svslabs.us') {
  // Dev firebase config
  var firebaseConfig = {
    apiKey: "AIzaSyD27zed17-nec-3Qbz5DTwQMB4vm0sdgrM",
    authDomain: "turant-dev-ivr-settings.firebaseapp.com",
    databaseURL: "https://turant-dev-ivr-settings-default-rtdb.firebaseio.com",
    projectId: "turant-dev-ivr-settings",
    storageBucket: "turant-dev-ivr-settings.appspot.com",
    messagingSenderId: "107591246821",
    appId: "1:107591246821:web:5cda6657610ee61a2d850c",
    measurementId: "G-B8LD61CDKK"
 }
} else {
    // Dev firebase config
    var firebaseConfig = {
    
      apiKey: "AIzaSyB6NS8XqFtjPLA9mDQxOxFtTcC-Cn0ofjc",
      authDomain: "turant-ivr-settings.firebaseapp.com",
      databaseURL: "https://turant-ivr-settings-default-rtdb.firebaseio.com",
      projectId: "turant-ivr-settings",
      storageBucket: "turant-ivr-settings.appspot.com",
      messagingSenderId: "1045501731025",
      appId: "1:1045501731025:web:3debe861d3121563e7e5f0",
      measurementId: "G-BHSTJ0V1PH",
  }
}

//  const firebaseConfig = {
 
//  // MT Firebase config

//   //  apiKey: "AIzaSyB6NS8XqFtjPLA9mDQxOxFtTcC-Cn0ofjc",
//   //  authDomain: "turant-ivr-settings.firebaseapp.com",
//   //  databaseURL: "https://turant-ivr-settings-default-rtdb.firebaseio.com",
//   //  projectId: "turant-ivr-settings",
//   //  storageBucket: "turant-ivr-settings.appspot.com",
//   //  messagingSenderId: "1045501731025",
//   //  appId: "1:1045501731025:web:3debe861d3121563e7e5f0",
//   //  measurementId: "G-BHSTJ0V1PH",



//   // Prod Fireabase config
//   // apiKey: "AIzaSyCwotXxTZp521Uh42dK2eEZHrjr17pYB70",
//   // authDomain: "turant-prod-ivr-settings.firebaseapp.com",
//   // databaseURL: "https://turant-prod-ivr-settings-default-rtdb.firebaseio.com",
//   // projectId: "turant-prod-ivr-settings",
//   // storageBucket: "turant-prod-ivr-settings.appspot.com",
//   // messagingSenderId: "165550991206",
//   // appId: "1:165550991206:web:0aba574d7bcbfc1e698aa7",
//   // measurementId: "G-XK28TX99GS"


//   // Dev firebase config
//   // apiKey: "AIzaSyD27zed17-nec-3Qbz5DTwQMB4vm0sdgrM",
//   // authDomain: "turant-dev-ivr-settings.firebaseapp.com",
//   // databaseURL: "https://turant-dev-ivr-settings-default-rtdb.firebaseio.com",
//   // projectId: "turant-dev-ivr-settings",
//   // storageBucket: "turant-dev-ivr-settings.appspot.com",
//   // messagingSenderId: "107591246821",
//   // appId: "1:107591246821:web:5cda6657610ee61a2d850c",
//   // measurementId: "G-B8LD61CDKK"



//  };


firebase.initializeApp(firebaseConfig);


export default firebase;
