// import { configureScope } from '@sentry/browser';
import * as ActionTypes from "../actions";
import { setAuthHeader } from "../../Comman/axiosConfig";
import {
  settToken,
  redirectToDashboard,
  settUser,
  settUserObj,
  setLoggedInUser,
  setSecretKey,
  setPermission
} from "../../Comman/functions";

export const defaultLoginState = {
  isAttemptingLogin: false,
  payload: null,
  loginError: null,
  logginOut: false,
};

export function loginReducer(state = defaultLoginState, action) {
  switch (action.type) {
    case ActionTypes.ATTEMPT_LOGIN:
      return {
        ...state,
        isAttemptingLogin: true,
        beginLoginTime: new Date().getTime(),
      };
 
    case ActionTypes.LOGIN_SUCCESS: {
      const redirectingUser = false;
      console.log('auth loagin user details = ');
      console.log(action.response);
      const userObj = JSON.stringify(action.response.roles[0]);
      const secret_key = JSON.stringify(action.response.secret_key);
      const loggedInUser = {
        username: action.response.username,
        email: action.response.email,
        id: action.response.id,
        profile: action.response.profile,
        bank_id :action.response.bank_id,
        bank_name: action.response.bank_name,
        name: action.response.name,
        last_login : action.response.last_login,
        isUserRegister : action.response.isUserRegister,
        city : action.response.city,
        state : action.response.state,
        country : action.response.country,
        mobile_no : action.response.mobile_no,
        permissions : action.response.permissions,
      };
      // Set user token in localstorage
      console.log("user obj = ");
      console.log(JSON.stringify(action.response));
      settToken(action.response.token);
      setPermission(JSON.stringify(action.response.permissions))
      setAuthHeader(action.response.token);
      settUser(action.response.username);
 
      settUserObj(userObj);
      setSecretKey(secret_key);
      setLoggedInUser(JSON.stringify(loggedInUser));
      // after login success redirect to login
      redirectToDashboard(true);

      return {
        ...state,
        isAttemptingLogin: false,
        payload: action.response,
      };
    }
    case ActionTypes.LOGIN_FAILURE:
      return {
        ...state,
        isAttemptingLogin: false,
        payload: null,
      };

    case ActionTypes.LOGOUT_ACTION:
      document.cookie =
        "accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=ytel.com; path=/;";
      localStorage.userToken = "";

      return {
        ...defaultLoginState,
        logginOut: true,
      };
    case ActionTypes.LOGOUT_SUCCESS:
      document.cookie =
        "accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=ytel.com; path=/;";
      localStorage.removeItem("access_token");
      localStorage.removeItem("permissions");
      localStorage.removeItem("role");
      localStorage.removeItem("loggedInUser");
      localStorage.removeItem("username");
      localStorage.removeItem("last_login");
      localStorage.removeItem("isUserRegister");
      localStorage.removeItem("city");
      localStorage.removeItem("state");
      localStorage.removeItem("country");
      localStorage.removeItem("mobile_no");
      localStorage.removeItem("secret_key");
      localStorage.removeItem("customer_id");
      
     
     

      return {
        ...defaultLoginState,
        logginOut: true,
      };

    default:
      return state;
  }
}
