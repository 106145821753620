export const FETCH_LOAN_TYPES = 'FETCH_LOAN_TYPES';
export const FETCH_LOAN_TYPES_SUCCESS = 'FETCH_LOAN_TYPES_SUCCESS';
export const FETCH_LOAN_TYPES_FAILURE = 'FETCH_LOAN_TYPES_FAILURE';


export function fetchLoanTypes() {
  return { type: FETCH_LOAN_TYPES };
}



