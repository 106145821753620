import React, { Fragment } from "react";
import "../../containers/common.css";
import {
  CAlert,
  CButton,
  CCol,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { ContentLoading, InvertContentLoader } from "../../../Comman/components";


export const ResetPassword = (props) => {
  const {
    username,
    password,
    confirm_password,
    validateInput,
    usernameError,
    passwordError,
    confirmPasswordError,
    errorMsg,
    resetMsg,
    handleLoginPage,
    handleChangeInput,
    submitForm,
    loading,
  } = props;
  return (
    <Fragment>
      <CForm>
        <h1>Reset Password</h1>

        <p className="text-muted">Reset password for access your account</p>
        {errorMsg && (
          <CAlert color="danger">
            {/*eslint-disable-next-line*/}
            {`Error : ${errorMsg}`}
          </CAlert>
        )}
        {resetMsg && (
          <CAlert color="success"> 
            {/*eslint-disable-next-line*/}
            {`Success : ${resetMsg} you can login with new password `}
            <CButton color="link" className="px-0" onClick={handleLoginPage}>
              {" "}
              Click here{" "}
            </CButton>
          </CAlert>
        )}
        <CInputGroup className="mb-3 input_class">
          <CInputGroupPrepend>
            <CInputGroupText>
              <CIcon name="cil-user" />
            </CInputGroupText>
          </CInputGroupPrepend>
          <CInput
            type="text"
            placeholder="Username"
            name="username"
            autoComplete="username"
            onChange={handleChangeInput}
            value={username}
            onBlur={(e) => {
              if (username.length > 0) {
                validateInput(e);
              }
            }}
          />
        </CInputGroup>
        <div
          className="validationErrorMessagesStyles"
          style={{ textAlign: "left" }}
        >
          {usernameError}
        </div>
        <CInputGroup className="mb-4 input_class">
          <CInputGroupPrepend>
            <CInputGroupText>
              <CIcon name="cil-lock-locked" />
            </CInputGroupText>
          </CInputGroupPrepend>
          <CInput
            type="password"
            placeholder="Password"
            name="password"
            autoComplete="password"
            onChange={handleChangeInput}
            value={password}
          />
        </CInputGroup>
        <div
          className="validationErrorMessagesStyles"
          style={{ textAlign: "left" }}
        >
          {passwordError}
        </div>
        <CInputGroup className="mb-4 input_class">
          <CInputGroupPrepend>
            <CInputGroupText>
              <CIcon name="cil-lock-locked" />
            </CInputGroupText>
          </CInputGroupPrepend>
          <CInput
            type="password"
            placeholder="Confirm Password"
            name="confirm_password"
            autoComplete="password"
            onChange={handleChangeInput}
            value={confirm_password}
          />
        </CInputGroup>
        <div
          className="validationErrorMessagesStyles"
          style={{ textAlign: "left" }}
        >
          {confirmPasswordError}
        </div>
        <CRow>
          <CCol xs="6">
            <CButton
              color="primary"
              className="px-4 login_bt"
              onClick={submitForm}
            >
              Reset
              {loading && <InvertContentLoader />}
            </CButton>
          </CCol>
        </CRow>
      </CForm>
    </Fragment>
  );
};
