export const FETCH_END_USER = 'FETCH_END_USER';
export const FETCH_END_USER_SUCCESS = 'FETCH_END_USER_SUCCESS';
export const FETCH_END_USER_FAILURE = 'FETCH_END_USER_FAILURE';


export function fetchEndUser(login_type) {
  return { type: FETCH_END_USER , login_type};
}



