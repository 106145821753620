import React, { Component } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import "./scss/style.scss";
import { Login, Reset, VerifyEmail } from "./components/Auth/containers/Auth";
import { getUserToken } from "./components/Comman/functions";
import {PageNotFound} from './components/Comman/components/index'

import {ApiManage, ApiDocumentation} from './components/API/containers/index'
import LoginSSO from "./components/SSO/LoginSSO";


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

 

const PrivateRoute = ({ component: TheLayout, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (getUserToken("access_token")) {
        return <TheLayout {...props} />;
      } 
      return (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      );
    }}
  />
);
class App extends Component {
  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/reset-password/:token"
              name="Reset"
              render={(props) => <Reset {...props} />}
            />
              <Route
              exact
              path="/verify-user/:id"
              name="Verify User"
              render={(props) => <VerifyEmail {...props} />}
            />

            <Route
              exact
              path="/500"
              name="500"
              render={(props) => <ApiDocumentation {...props} />}
            />

            <Route
              exact
              path="/sso-auth-login/:id"
              render={(props) => <LoginSSO {...props}  />}
            />


            <PrivateRoute
              path="/"
              name="Home"
              component={TheLayout}
              props={this.props}
            />
             
            {/* } /> */}
            {/* <Route path="/" name="Login Page"  render={props => <Login {...props}/>} /> */}
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
